<template>
  <div>
    <div class="headerLayout">
      <div class="headerCon ">
        <h1 id="mallLogo">
          <a
            href="/"
            class="header-logo"
          ><img src="../../assets/logo-banner.png"></a>
        </h1>
      </div>
    </div>
    <div class="joinin-apply">
      <el-steps
        :active="stepsActive"
        finish-status="success"
      >
        <el-step title="入住须知" />
        <el-step title="公司信息认证" />
        <el-step title="店铺信息认证" />
      </el-steps>

      <el-form
        ref="applyForm"
        :model="applyForm"
        :rules="rules1"
        label-width="150px"
        style="max-width: 800px"
        v-if="stepsActive==0"
      >
        <div class="apply-zl">
          <h2 class="">请按照提示填写本人真实的资料</h2>
          <el-form-item
            label="店铺名称"
            style="max-width: 460px"
            prop="shopName"
          >
            <el-input v-model="applyForm.shopName" />
          </el-form-item>
          <el-form-item
            label="详细地址"
            style="max-width: 460px"
            prop="detailedAddress"
          >
            <el-input v-model="applyForm.detailedAddress" />
          </el-form-item>
          <el-form-item
            label="姓名"
            style="max-width: 460px"
            prop="name"
          >
            <el-input v-model="applyForm.name" />
          </el-form-item>
          <el-form-item
            label="联系人电话"
            style="max-width: 460px"
            prop="telephone"
          >
            <el-input v-model="applyForm.telephone" />
          </el-form-item>
          <el-form-item
            label="电子邮箱"
            style="max-width: 460px"
            prop="email"
          >
            <el-input v-model="applyForm.email" />
          </el-form-item>
        </div>
        <div class="apply-khxx">
          <h2>开户银行信息</h2>
          <el-form-item
            label="银行开户名"
            style="max-width: 460px"
            prop="accountName"
          >
            <el-input v-model="applyForm.accountName" />
          </el-form-item>
          <el-form-item
            label="个人银行账号"
            style="max-width: 460px"
            prop="bankAccount"
          >
            <el-input v-model="applyForm.bankAccount" />
          </el-form-item>
          <el-form-item
            label="开户银行支行名称"
            style="max-width: 460px"
            prop="bankName"
          >
            <el-input v-model="applyForm.bankName" />
          </el-form-item>
          <el-form-item
            label="支行联行号"
            style="max-width: 460px"
            prop="bankId"
          >
            <el-input v-model="applyForm.bankId" />
          </el-form-item>
        </div>
        <br>
      </el-form>

      <el-form
        :model="applyForm"
        label-width="150px"
        style="max-width: 800px"
        v-if="stepsActive==1"
      >
        <div class="apply-dpxx">
          <h2 class="">店铺经营信息</h2>
          <el-form-item
            label="店铺名称"
            style="max-width: 460px"
            prop="shopName"
          >
            <el-input v-model="applyForm.shopName" />
            <p class="apply-p">店铺名称注册后不可以修改，请认真填写</p>
          </el-form-item>
          <el-form-item
            label="店铺等级"
            style="max-width: 460px"
          >
            <el-select
              v-model="applyForm.appGrade"
              placeholder="请选择"
            >
              <el-option
                v-for="item in appGrade1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="店铺分类"
            style="max-width: 580px"
          >
            <el-select
              v-model="applyForm.appClassify"
              placeholder="请选择"
            >
              <el-option
                v-for="item in appClassify1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <p class="apply-p">请根据您所经营的内容认证选择店铺分类，注册后商家不可自行修改</p>
          </el-form-item>
        </div>
        <br>

      </el-form>
      <el-form
        v-if="stepsActive==2"
        class="allpy-sh"
      >
        <h2 class="">驻申请已经提交，请等待管理员审核</h2>

      </el-form>

      <div class="apply-auto">
        <el-button
          v-if="stepsActive==1"
          type="danger"
          style="margin-top: 12px"
          @click="previousStep"
        >上一步</el-button>
        <el-button
          v-if="stepsActive==0"
          type="danger"
          style="margin-top: 12px"
          @click="nextStep('applyForm')"
        >下一步</el-button>
        <el-button
          v-if="stepsActive==1"
          type="danger"
          style="margin-top: 12px"
          @click="nextStep('applyForm')"
        >提交</el-button>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>
<script>
import Footer from "../../components/Footer.vue";
export default {
  name: "EdiApply",
  components: {
    Footer,
  },
  data() {
    return {
      applyForm: {
        shopName: "",
        appGrade: "",
        detailedAddress: "",
        appClassify: "",
      },
      appGrade1: [
        {
          value: "0",
          label: "高级店铺",
        },
        {
          value: "1",
          label: "中级店铺",
        },
        {
          value: "2",
          label: "初级店铺",
        },
      ],
      appClassify1: [
        {
          value: "1",
          label: "日用品",
        },
        {
          value: "2",
          label: "艺术品",
        },
        {
          value: "3",
          label: "外设产品",
        },
        {
          value: "4",
          label: "通讯",
        },
        {
          value: "5",
          label: "家纺",
        },
      ],
      stepsActive: 0,
      rules1: {
        shopName: [
          { message: "请输入店铺名称", required: true, trigger: "blur" },
        ],
        detailedAddress: [
          { message: "请输入详细地址", required: true, trigger: "blur" },
        ],
        name: [{ message: "请输入姓名", required: true, trigger: "blur" }],
        telephone: [
          { message: "请输入联系人电话", required: true, trigger: "blur" },
        ],
        email: [{ message: "请输入电子邮箱", required: true, trigger: "blur" }],
        accountName: [
          { message: "请输入银行开户名", required: true, trigger: "blur" },
        ],
        bankAccount: [
          { message: "请输入个人银行账号", required: true, trigger: "blur" },
        ],
        bankName: [
          {
            message: "请输入开户银行支行名称",
            required: true,
            trigger: "blur",
          },
        ],
        bankId: [
          { message: "请输入支行联行号", required: true, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (window.sessionStorage.getItem("stepsActive")) {
      this.stepsActive = window.sessionStorage.getItem("stepsActive")
    }
  },
  methods: {
    nextStep() {
      if (this.stepsActive == 0) {
        this.$refs.applyForm.validate((valid) => {
          if (valid) {
            if (this.stepsActive < 2) {
              this.stepsActive++;
            }
          } else {
            return false;
          }
        });
      } else if (this.stepsActive == 1) {
        if (this.stepsActive < 2) {
          this.stepsActive++;
          window.sessionStorage.setItem("stepsActive", this.stepsActive);
        }
      }
      console.log(this.stepsActive);
    },
    previousStep() {
      if (this.stepsActive != 0) {
        this.stepsActive--;
      }
      console.log(this.stepsActive);
    },
  },
};
</script>
<style scoped lang='less'>
.header-logo {
  display: inline-block;
  width: 400px;
  img {
    width: 100%;
    height: auto;
  }
}
.joinin-apply {
  width: 790px;
  padding: 20px 100px;
  margin: 20px auto;
}

.apple-hint {
  color: #1279bb;
  line-height: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: dashed 1px #cbdde6;
}

.apply-zl {
  border-bottom: dashed 1px #cbdde6;
  padding-bottom: 30px;
}

.apply-zl h2 {
  font-size: 20px;
  color: #333;
  height: 22px;
  padding: 10px 0;
}

.apply-khxx {
  border-bottom: dashed 1px #cbdde6;
  padding-top: 20px;
  padding-bottom: 30px;
}

.apply-khxx h2 {
  font-size: 20px;
  color: #333;
  height: 22px;
  padding: 10px 0;
}

.apply-dpxx {
  border-bottom: dashed 1px #cbdde6;
  padding-bottom: 30px;
}

.apply-dpxx h2 {
  font-size: 20px;
  color: #333;
  height: 22px;
  padding: 10px 0;
}

.allpy-sh {
  font: 32px;
  color: #777;
  text-align: center;
  margin: 120px 0 100px 0;
}

.apply-p {
  color: #f30;
  font-size: 14px;
  padding: 5px 0;
}

.apply-auto {
  width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>