<template>
  <div class="shopping">
    <div class="shopping_tit">
      <div class="shopping_shopping">
        <a href="/"><img
            class="header-shoppingo"
            src="../../assets/logo-banner.png"
          ></a>
      </div>

        </div>
        <div class="shopping_relative">
            <div class="shopping_form">
                <h2 class="shopping_text">收银台</h2>
                <div>
          <el-form
            :model="formLabelAlign"
            status-icon
            class="demo-ruleForm"
            :rules="rules"
          >
            <el-form-item>
              <el-input
                v-model="formLabelAlign.inputName"
                prefix-icon="user"
                placeholder="收货人"
                width="300px"
                height="40px"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formLabelAlign.region"
                prefix-icon="location"
                placeholder="所在地区"
                width="300px"
                height="40px"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formLabelAlign.address"
                prefix-icon="location"
                placeholder="详细地址"
                width="300px"
                height="40px"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formLabelAlign.postal"
                prefix-icon="message"
                placeholder="邮编"
                width="300px"
                height="40px"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formLabelAlign.phoneNumber"
                prefix-icon="iphone"
                placeholder="手机"
                width="300px"
                height="40px"
              />
            </el-form-item>
            <div class="shopping-center">
              <img
                :src="productList[productListIndex].productPic"
                class="goodsimg pic_img_6812"
                id="goods_bimg"
                width="200"
                height="200"
                style="opacity: 1;"
              >
              <div class="shopping-text">
                <h2>{{productList[productListIndex].productName}}</h2>
                <span class="price">售价</span><strong
                  class="p-price"
                  id="ECS_GOODS_AMOUNT"
                >¥{{productList[productListIndex].price}}</strong>
              </div>
            </div>

            <div class="shopping-bottom">
              <div>微信支付</div>
            </div>

            <el-form-item>
              <el-button
                class="shopping-btn"
                type="primary"
                @click="submitForm()"
              >去付款</el-button>
            </el-form-item>
          </el-form>
        </div>
            </div>
        </div>
        <el-dialog
            v-model="dialogVisible"
            title="请扫码支付"
            width="50%"
            center
        >
            <div style="text-align: center">
                <div id="myQrcode"></div>
            </div>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="paySuccess">已支付</el-button>
            </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import axios from 'axios'
import productList from "../../api/goods.js";

export default {
    name: "EdiShopping",
    data() {
        return {
            formLabelAlign: {
                inputName: "",
                inputPasswode1: "",
                checked2: false
            },
            productList: [],
            rules: {
                harvester: [{ required: true, message: '收货人不能为空', trigger: 'change' }],
                region: [{ required: true, message: '所在地区不能为空', trigger: 'change' }],
                address: [{ required: true, message: '详细地址不能为空', trigger: 'change' }],
                phoneNumber: [{ required: true, message: '手机号不能为空', trigger: 'change' }]
            },
            productListIndex: '',
            loginToken: false,
            dialogVisible: false,
            payImgUrl: ''
        };
    },
    created() {
        this.productList = productList
        this.productListIndex = productList.findIndex(item => item.productId == this.$route.query.id);
        if (window.sessionStorage.getItem("loginToken")) {
            this.loginToken = window.sessionStorage.getItem("loginToken")
            this.inputName = window.sessionStorage.getItem("inputName")
        }
        if (this.loginToken == false) {
            this.$router.push('/login');
        }
    },
    methods: {
        getCodeUrl() {
            this.dialogVisible = true
            axios.post('https://fuwu.czzhongbo.com/rfq/wxpay/pay', {
                total: this.productList[this.productListIndex].price * 100,
                description: '众博EDI'
            })
                .then(res => {
                    if (res.data.code === '0') {
                        this.payImgUrl = res.data.data.code_url
                        window.jQuery('#myQrcode canvas').remove();
                        window.jQuery('#myQrcode').qrcode({
                            text: this.payImgUrl
                        });
                    }
                })
        },
        paySuccess() {
            this.$message({
                message: '支付成功',
                type: 'success'
            })
            window.sessionStorage.setItem("loginToken", true);
            this.$router.push('/')
        },
        getId() {
            let s = [];
            let hexDigits = "0123456789abcdef";
            for (let i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4";
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
            s[8] = s[13] = s[18] = s[23] = "-";

            let uuid = s.join("");
            return uuid.replace(/-/g, '');
        },
        submitForm() {
            if (this.formLabelAlign.inputName !== '') {
                this.getCodeUrl()
            } else(
                this.$message({
                    message: '信息填写错误',
                    type: 'warning'
                })
            )
        }
    }
}
</script>
<style scoped lang="less">
.shopping {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
}

.shopping_tit {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.shopping_tit .shopping_shopping {
  width: 290px;
  height: 70px;
  text-align: center;
  line-height: 70px;
}

.shopping_text {
  font-size: 38px;
}

.shopping_relative {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.shopping_form {
  overflow: hidden;
  width: 100%;
  height: auto;
  z-index: 1;
  overflow: visible;
}

.el-form-item {
  width: 400px;
  margin-top: 30px;
}

.header-shoppingo {
  width: 290px;
}

.shopping-center {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .shopping-text {
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;

    h2 {
      font-size: 18px;
      color: #333;
      margin: 20px 0;
    }

    span {
      font-size: 16px;
      color: #333;
    }

    .p-price {
      font-size: 18px;
      color: #ff7300;
    }
  }
}

.shopping-bottom {
  width: 100%;
  margin: 20 auto;
  padding: 40px 20px;
  font-size: 28px;
  border: 2px solid #ff7300;
  border-radius: 10px;
}

.shopping-btn {
  width: 200px;
  height: 40px;
  text-align: center;
  background: #ff7300;
  border: none;
  display: block;
}

.el-button:hover {
  background: #ff7300;
}
</style>