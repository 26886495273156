import {
    createRouter,
    createWebHistory
} from "vue-router";


// 引入路由组件
import Home from '@/views/Home/Home.vue'
import Search from '@/views/Search/Search.vue'
import Login from '@/views/Login/Login.vue'
import Signup from '@/views/Login/Signup.vue'
import Article from '@/views/Login/Article.vue'
import Privacy from '@/views/Login/Privacy.vue'
import Register from '@/views/Register/Register.vue'
import Goods from '@/views/Goods/goods.vue'
import Apply from '@/views/Apply/apply.vue'
import Supplier from '@/views/Supplier/supplier.vue'
import Shopping from '@/views/Shopping/index.vue'
const routes = [{
        path: '/home',
        component: Home
    }, {
        path: '/search',
        component: Search
    }, {
        path: '/login',
        component: Login
    },{
        path: '/signup',
        component: Signup
    },{
        path: '/article',
        component: Article
    }, {
        path: '/privacy',
        component: Privacy
    }, {
        path: '/register',
        component: Register
    }, {
        path: '/goods',
        component: Goods
    }, {
        path: '/apply',
        component: Apply
	}, {
        path: '/Supplier',
        component: Supplier
	}, {
        path: '/shopping',
        component: Shopping
	},{
        path: '/:pathMatch(.*)*',
        component: Home
    }

],
VueRouter = createRouter({
    history: createWebHistory(),
    routes
});

export default VueRouter;