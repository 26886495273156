<template>
    <div>注册</div>
</template>
<script>
export default {
    name: 'EdiRegister'
}
</script>
<style scoped lang='less'>

</style>