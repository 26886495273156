<template>
    <div>222</div>
</template>
<script>
export default {
    name: 'EdiSearch'
}
</script>
<style scoped lang='less'>

</style>