<template>
  <Header></Header>
  <div class="goods-container">
    <div id="product-intro" class="goods-info">
      <div id="preview">
        <div class="goods-img" id="li_6812">
          <a :href="productList[productListIndex].productPic" class="MagicZoom" id="zoom"
            rel="zoom-position: right;"
            style="position: relative; display: inline-block; text-decoration: none; outline: 0px; width: 400px;"
            title=""><img :src="productList[productListIndex].productPic" class="goodsimg pic_img_6812" id="goods_bimg"
              width="400" height="400" style="opacity: 1;">
            <div class="MagicZoomBigImageCont"
              style="overflow: hidden; z-index: 100; top: -100000px; position: absolute; width: 350px; height: 350px; opacity: 1; left: 415px;">
              <div class="MagicZoomHeader"
                style="position: relative; z-index: 10; left: 0px; top: 0px; padding: 3px; display: none; visibility: hidden;">
              </div>
              <!-- <div style="overflow: hidden;"><img
                  src="http://kunhaimeta.com/images/202203/source_img/6812_P_1648259142855.png"
                  style="padding: 0px; margin: 0px; border: 0px; position: relative; left: 0px; top: 0px;"></div> -->
            </div>
            <div class="MagicZoomPup"
              style="z-index: 10; position: absolute; overflow: hidden; display: none; visibility: hidden; width: 398px; height: 398px; opacity: 0.5; left: 0px; top: 0px;">
            </div>
          </a>
        </div>
        <div style="height:10px; line-height:10px; clear:both;"></div>

        <div class="clearfix">
          <span class="scrleft"></span>
          <div class="gallery">
            <div id="demo" style="width: 360px;">
              <div id="demo1" style="float: left; width: 60px;">
                <ul id="goods_gallery">
                  <!-- <li> <a
                    href="images/202203/source_img/6812_P_1648259142855.png"
                    rel="zoom-id: zoom;"
                    rev="images/202203/goods_img/6812_P_1648259142706.jpg"
                    title=""
                    style="outline: 0px;"
                  > <img
                      src="images/202203/thumb_img/6812_thumb_P_1648259142626.jpg"
                      alt="厨房水龙头置物架 免打孔不锈钢浴室沥水架 水槽水池收纳架"
                      class="B_blue"
                    > </a> </li> -->
                </ul>
              </div>
              <div id="demo2" style="display: inline; overflow: visible; width: 60px;"></div>
            </div>
          </div>
          <!-- <span
          class="scrright"
          onmouseover="moveRight()"
          onmousedown="clickRight()"
          onmouseup="moveRight()"
          onmouseout="scrollStop()"
        ></span> -->
        </div>
      </div>
      <div class="goods-detail-info">
        <form action="javascript:addToCart(6812)" method="post" name="ECS_FORMBUY" id="ECS_FORMBUY">
          <div class="goods-name">
            <h1>{{productList[productListIndex].productName}}</h1>
          </div>
          <div id="goods-price">
            <div class="mar-l">

              <span class="price">售价</span><strong class="p-price"
                id="ECS_GOODS_AMOUNT">¥{{productList[productListIndex].price}}</strong>
            </div>
            <div class="show-price">
              <div class="market-prices-spe">
                <span class="price">市场价</span>
                <font class="market-price">¥{{productList[productListIndex].price}}</font>
              </div>

              <div class="rank-prices">
                <div id="vip1" style="display: block;">
                  <span class="rmbPrice">会员等级价格<i></i></span>
                </div>
                <div class="hover" style="display: none;" id="vip2" onmouseover="" onmouseout="">
                  <span class="rmbPrice">会员等级价格<i></i></span>

                  <br>
                  <span class="rmbPrice"> 普通会员：¥11.0</span>

                  <br>
                  <span class="rmbPrice"> 铜牌会员：¥10.4</span>

                  <br>
                  <span class="rmbPrice"> 金牌会员：¥9.9</span>

                  <br>
                  <span class="rmbPrice"> 钻石会员：¥9.3</span>

                </div>
              </div>

            </div>
          </div>
          <div id="summary-qita">
            <ul class="qita">
              <li>
                <p>累积评价<span><a href="">0人评价</a></span></p>
              </li>
              <li>
                <p>累计销量<span>0</span></p>
              </li>
              <li style="border:none">
                <p>赠送积分<span>
                    <font id="ECS_GOODS_AMOUNT_jf">11</font>
                  </span></p>
              </li>
            </ul>
          </div>
          <ul id="choose" class="choose_bor">
            <li id="choose-amount">
              <div class="dt">数量</div>
              <div class="dd">
                <div class="wrap-input">
                  <a class="btn-reduce" href="javascript:;">减少数量</a>
                  <input name="number" type="text" class="text" id="number" value="1">
                  <a class="btn-add" href="javascript:;">增加数量</a>
                  （库存<font id="shows_number">100</font>）
                </div>
              </div>
            </li>
          </ul>
          <div class="buyNub-buy-wrap">
            <div id="choose-btns1" class="buyNub-buy" style="display:none">
              <a class="tell-me"><i></i>到货通知</a>
            </div>
            <div id="choose-btns" class="buyNub-buy" style="display: block;">

              <!-- <a href="javascript:addToCart(6812,0,1)" name="bi_addToCart" class="u-buy1">立即购买</a> <a -->
              <a name="bi_addToCart" class="u-buy1"  @click="toGoods(productList[productListIndex].productId)">立即购买</a> <a
                href="javascript:addToCart(6812)" name="bi_addToCart" class="u-buy2">加入购物车</a>

            </div>
          </div>
        </form>
      </div>

      <div id="supp_info">

        <div id="shop-info">
          <input type="hidden" id="chat_supp_id" value="1">
          <dl class="shop-title">
            <dt><a href="supplier.php?suppId=1" target="_blank">{{productList[productListIndex].mallName}}</a></dt>
          </dl>
          <div class="blank"></div>
          <div class="shop-info-detail">
            <dl style="padding-top:10px;">
              <dt>商家名称：</dt>
              <dd>{{productList[productListIndex].mallName}}</dd>
            </dl>
            <dl>
              <dt>商店等级：</dt>
              <dd>初级店铺</dd>
            </dl>
            <dl>
              <dt>客服 QQ：</dt>
              <dd>

              </dd>
            </dl>
            <dl>
              <dt>客服旺旺：</dt>
              <dd>
              </dd>
            </dl>
            <dl>
              <dt>所在地区：</dt>
              <dd>河北</dd>
            </dl>
            <div id="enter-shop">
              <div class="enter-shop-item">
                <a class="btn-flat1 goto-shop" @click="goMall" target="_blank"><i></i>进入商店 </a>
                <!-- <a
                  class="btn-flat1 shop-add"
                  href="javascript:guanzhu(1);"
                ><i></i>关注本店 </a> -->
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
  <div class="goods_flex">
    <div class="goods_ggcs">
      <h2>规格参数</h2>
      <span>商品名称:{{productList[productListIndex].productName}}</span>
      <!-- <span>商品编号：ECS006812</span> -->
      <!-- <span>品牌:</span> -->
      <span>上架时间:2022-5-10</span>
    </div>
    <div class="goods_xqt">
      <h2>详情图</h2>
      <img :src="productList[productListIndex].productPic"
        style="padding: 0px; margin: 0px; border: 0px; position: relative; left: 0px; top: 0px;">
    </div>
  </div>
  <Footer></Footer>
</template>
<script>
  import Header from "../../components/Header.vue";
  import Footer from "../../components/Footer.vue";
  import productList from "../../api/goods.js";
  export default {
    name: "EdiGoods",
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        productList: [],
        productListIndex: ''
      };
    },
    created() {
      this.productList = productList
      this.productListIndex = productList.findIndex(item => item.productId == this.$route.query.id);
    },
    methods: {
      goMall() {
        this.$router.push('supplier?mallId=' + this.productList[this.productListIndex].mallId);
      },
      toGoods(id) {
      this.$router.push('/shopping?id=' + id);
    }
    },
  };
</script>
<style scoped lang='less'>
  .goods-container {
    width: 1200px;
    margin: 10px auto;
  }

  .goods-info {
    height: auto;
    display: flex;
    margin-bottom: 10px;
  }

  /*商品图展示*/
  #preview {
    z-index: 2;
    width: 420px;
    height: auto;
  }

  #preview .goods-img {
    padding: 10px;
    width: 400px;
    border: 1px solid #eee;
  }

  #preview .goods-img img {
    width: 400px;
    height: 400px;
  }

  /*放大镜*/
  .MagicZoomBigImageCont {
    padding: 10px !important;
    border: 1px solid #ddd;
    margin-top: -11px;
    background: #fff;
  }

  #preview .goods-img .MagicZoomBigImageCont img {
    width: auto;
    height: auto;
  }

  .MagicZoomPup {
    border: 1px solid #eee;
    // background: url(../images/point.png);
    cursor: move;
  }

  #preview span.scrleft {
    width: 16px;
    height: 50px;
    // background: url(../images/btn_per.gif) no-repeat left center;
    float: left;
    cursor: pointer;
  }

  #preview span.scrright {
    width: 16px;
    height: 50px;
    // background: url(../images/btn_next.gif) no-repeat right center;
    float: right;
    cursor: pointer;
  }

  #preview .gallery {
    width: 352px;
    float: left;
    margin-left: 8px;
    _margin-left: 5px;
    overflow: hidden;
  }

  #preview .gallery ul {
    width: auto;
  }

  #preview .gallery #goods_gallery .curr img {
    border: 1px #cd0000 solid;
  }

  #preview .gallery li {
    float: left;
    width: 60px;
  }

  #preview .gallery li img {
    width: 50px;
    height: 50px;
    display: block;
    border: 1px #eee solid;
  }

  .goods-gallery-bottom {
    position: relative;
    padding: 10px;
    height: 25px;
    line-height: 25px;
    clear: both;
  }

  .goods-gallery-bottom .goods-sn span {
    float: left;
    margin-right: 5px;
    color: #999;
  }

  .goods-gallery-bottom a b {
    display: block;
    width: 14px;
    height: 14px;
    // background: transparent url(../images/goods-icon.png) no-repeat;
    float: left;
    margin: 6px 4px -2px 0px;
  }

  .goods-gallery-bottom a.goods-col {
    overflow: hidden;
    height: 25px;
    line-height: 25px;
    color: #999;
    margin-right: 10px;
  }

  .goods-gallery-bottom a.goods-col-t {
    color: #e31939;
  }

  .goods-gallery-bottom a.goods-col b {
    background-position: -111px -221px;
  }

  .goods-gallery-bottom a.goods-col-t b {
    background-position: -111px -197px;
  }

  .goods-gallery-bottom a.goods-compare {
    width: auto;
    height: 25px;
    overflow: hidden;
    padding-left: 18px;
    color: #999;
    position: relative;
    cursor: pointer;
  }

  .goods-gallery-bottom a.goods-compare i {
    // background: url(../images/common/common-icon.png) no-repeat -26px -11px;
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    left: 0px;
    top: 5px;
  }

  .goods-gallery-bottom a.goods-compare.curr i,
  .goods-gallery-bottom a.goods-compare:hover i {
    background-position: -40px -11px;
  }

  .goods-gallery-bottom a.goods-compare.curr {
    color: #e31939;
  }

  .goods-gallery-bottom a:hover i {
    color: #e31939;
  }

  /*详细信息*/
  .goods-detail-info {
    width: 530px;
    padding: 0px 20px;
  }

  .goods-name h1 {
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    color: #3c3c3c;
  }

  .goods-brief {
    color: #999;
  }

  #goods-price {
    background: #f5f5f5;
    margin: 10px 0px;
    padding: 6px 15px;
    z-index: 2;
    position: relative;
  }

  #goods-price .mar-l {
    height: 35px;
    line-height: 35px;
  }

  #goods-price .mar-l .price {
    display: inline-block;
    width: 45px;
  }

  #goods-price .mar-l .p-price {
    font-size: 30px;
    color: #f52648;
  }

  #goods-price .mar-l .depreciate {
    display: inline-block;
    width: 60px;
    text-align: center;
    background: #fe596a;
    height: 20px;
    line-height: 20px;
    margin-left: 50px;
    font: 12px/150% microsoft yahei;
  }

  #goods-price .mar-l .depreciate a {
    color: #ffffff;
    text-decoration: none;
  }

  #goods-price .show-price {
    height: 35px;
    line-height: 35px;
    z-index: 101;
  }

  #goods-price .show-price .market-prices-spe {
    float: left;
  }

  #goods-price .show-price .market-prices-spe .market-price {
    font-weight: normal;
    text-decoration: line-through;
    margin-left: 10px;
    margin-right: 50px;
  }

  #goods-price .show-price .rank-prices {
    font-weight: normal;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    float: left;
    margin-top: 5px;
    z-index: 100;
  }

  #goods-price .show-price .rank-prices .rmbPrice i {
    // background: url(../images/common/common-icon.png) no-repeat;
    background-position: -294px -110px;
    width: 7px;
    height: 5px;
    display: inline-block;
    margin-top: -1px;
    margin-left: 10px;
    vertical-align: middle;
  }

  #goods-price .show-price .rank-prices #vip1 {
    padding: 2px 8px;
    border: 1px solid #f3f3f3;
    cursor: pointer;
  }

  #goods-price .show-price .rank-prices #vip2 {
    padding: 2px 8px;
    width: auto;
    height: auto;
    background: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    z-index: 100;
  }

  #summary-qita {
    margin-bottom: 10px;
    height: 45px;
    display: block;
    clear: both;
  }

  #summary-qita ul.qita {
    height: 25px;
    display: block;
    border-top: 1px #e5dfda dotted;
    border-bottom: 1px #e5dfda dotted;
    padding: 10px 0px;
  }

  #summary-qita ul.qita li {
    width: 33%;
    height: 25px;
    text-align: center;
    display: block;
    float: left;
    border-right: 1px #e5dfda solid;
  }

  #summary-qita ul.qita li span {
    width: auto;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    font-size: 12px;
    color: #f52648;
    margin-left: 5px;
  }

  #summary-qita ul.qita li span a {
    color: #f52648;
  }

  #summary-qita ul.qita li p {
    height: 25px;
    line-height: 25px;
    color: #999999;
  }

  #choose {
    width: 100%;
  }

  #choose li {
    padding: 0 0 7px 0px;
    overflow: visible;
    position: relative;
    margin-left: 5px;
  }

  #choose li .dt {
    float: left;
    width: 55px;
    color: #999;
  }

  .pickup-point-wrap {
    position: relative;
    text-align: left;
  }

  .pickup-point-wrap .dt {
    padding: 6px 0px;
  }

  #choose #choose-amount {
    overflow: hidden;
    padding-top: 5px;
    margin-left: 5px;
  }

  #choose #choose-amount dt {
    float: left;
    width: 55px;
    color: #999;
    padding: 5px 0px;
  }

  #choose #choose-amount .dd {
    overflow: hidden;
    width: 470px;
    float: left;
  }

  #choose-amount .wrap-input {
    padding: 0px 18px 0px 0px;
    width: 200px;
  }

  #choose-amount .wrap-input a {
    overflow: hidden;
    width: 15px;
    line-height: 50px;
    height: 15px;
    float: left;
    margin-top: 3px;
  }

  #choose-amount .wrap-input font {
    font-size: 12px;
  }

  #choose-amount .btn-reduce {
    background-position: -44px -25px;
    margin-right: 5px;
  }

  #choose-amount .btn-add {
    background-position: -60px -25px;
    margin-left: 5px;
  }

  #choose-amount .btn-reduce:hover {
    background-position: -44px -41px;
  }

  #choose-amount .btn-add:hover {
    background-position: -60px -41px;
  }

  #choose-amount .text {
    border: #ccc 1px solid;
    padding: 2px;
    width: 30px;
    height: 16px;
    text-align: center;
    float: left;
  }

  #choose-btns {
    float: left;
    height: 40px;
    width: 100%;
  }

  #choose-btns .u-buy1 {
    display: block;
    width: 160px;
    height: 38px;
    line-height: 38px;
    font-size: 16px;
    color: #fff;
    float: left;
    cursor: pointer;
    background: #fff0ea;
    margin-right: 15px;
    color: #e31939;
    text-align: center;
    text-decoration: none;
    border: none;
    border: 1px solid #e31939;
  }

  #choose-btns .u-buy2 {
    display: block;
    width: 160px;
    height: 38px;
    line-height: 38px;
    font-size: 16px;
    float: left;
    margin-right: 15px;
    cursor: pointer;
    background: #e31939;
    border: 1px solid #e31939;
    color: #fff;
    text-align: center;
    text-decoration: none;
  }

  #choose-btns a.btn-phone,
  #choose-btns1 a.btn-phone {
    width: auto;
    line-height: 40px;
    height: 40px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    float: right;
    text-decoration: none;
  }

  #choose-btns a.btn-phone i,
  #choose-btns1 a.btn-phone i {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
    background-position: -56px -213px;
  }

  /*详细信息右侧店铺信息*/
  #supp_info {}

  #shop-info {
    float: right;
    width: 220px;
    min-height: 450px;
    position: relative;
  }

  #shop-info .shop-title {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    background: #e31939;
    padding: 8px 10px;
    line-height: 24px;
  }

  #shop-info .shop-title dt {
    width: 100%;
  }

  #shop-info .shop-title span,
  #shop-info .shop-title a {
    color: #fff;
  }

  #shop-info .shop-info-detail {
    border: 1px solid #eee;
    border-top: 0;
  }

  #shop-info dl {
    padding: 0 14px;
    overflow: hidden;
    line-height: 24px;
  }

  #shop-info dt {
    float: left;
    width: 60px;
    padding-bottom: 0px;
    color: #999;
  }

  #shop-info dd {
    width: 125px;
    clear: none;
    overflow: hidden;
    zoom: 1;
  }

  #shop-info .service-promise {
    overflow: hidden;
    padding: 15px 14px 5px;
  }

  #shop-info .service-promise li {
    width: 57px;
    height: 57px;
    padding: 0;
    overflow: hidden;
    // background:url(../images/goods-icon.png) no-repeat;
    float: left;
    margin-right: 9px;
  }

  #shop-info .service-promise .service-promise1 {
    background-position: 0 -455px;
  }

  #shop-info .service-promise .service-promise2 {
    background-position: 0 -512px;
  }

  #shop-info .service-promise .service-promise3 {
    background-position: 0 -569px;
  }

  #enter-shop {
    padding: 10px 14px;
  }

  #enter-shop a {
    display: block;
    color: #f52648;
    line-height: 31px;
    height: 31px;
    text-align: center;
  }

  #enter-shop a:hover {
    text-decoration: none;
  }

  #enter-shop .shop-customer {
    margin-left: -10px;
    width: 200px;
  }

  #enter-shop .btn-customer {
    float: left;
    line-height: 27px;
    height: 27px;
    border: 1px solid #eee;
    background: -moz-linear-gradient(top, #fafafa, #f7f7f7);
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#fafafa), to(#f7f7f7));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fafafa", endColorstr="#f7f7f7");
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr="
      #fafafa ", endColorstr="#f7f7f7 ")";
    color: #666;
    width: 58px;
    padding: 0 0 0 30px;
    position: relative;
    z-index: 1;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    margin: 0 0 10px 10px;
  }

  #enter-shop .btn-customer i {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  #enter-shop .btn-customer.btn-customer-ww {
    width: 64px;
    padding: 0 0 0 24px;
  }

  #enter-shop .btn-customer.btn-customer-ww i {
    left: 10px;
  }

  #enter-shop #attention-shop {
    padding-top: 5px;
    text-align: center;
    margin: 0px 10px;
  }

  #enter-shop #attention-shop p {
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  .goods_flex {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;


    .goods_ggcs {
      width: 25%;
      margin: 0 auto;
      border-top: 1px solid #eee;


      h2 {
        font-size: 22px;
        line-height: 40px;
        background: #fafafa;
      }

      span {
        line-height: 30px;
        margin-right: 30px;
        display: block;
      }
    }

    .goods_xqt {
      width: 70%;
      margin: 0 auto 70px;
      border-top: 1px solid #eee;
      padding: 0 15px;

      h2 {
        font-size: 22px;
        line-height: 40px;
        text-align: left;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
</style>