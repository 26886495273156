<template>
  <div class="header_container">
    <div class="nav_container clearfix">
      <ul class="left">
        <li>众博商城欢迎你!</li>
        <!-- <template v-if="!loginToken">
            <li>
              <router-link class="header-login" to="/login">请登录</router-link>
            </li>
            <li>|</li>
            <li>
              <router-link class="header-signup" to="/signup">免费注册</router-link>
            </li>
        </template>
        <template v-else>
            <li>
             您好！
            </li>
            <li>
              {{inputName}}
            </li>
        </template> -->

      </ul>
      <ul class="right">
        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <li>
            <el-menu-item index="2"> <router-link class="header-signup" to="/">首页</router-link></el-menu-item>
          </li>
          <!-- <li>
            <span>|</span> 
          </li>
          <li>
            <el-menu-item index="1"><router-link class="header-signup" to="/apply">商家入驻</router-link></el-menu-item>
          </li>
          <li>
            <span>|</span>
          </li> -->
          <li>
            <span></span> 
          </li>
          <li>
            <span>|</span>
          </li>
          <li>
            <span></span>
          </li>
          
          <li>
            <el-sub-menu index="2">
              <template #title>商家支持</template>
              <el-menu-item index="2-1">商家流程</el-menu-item>
              <el-menu-item index="2-2">购物流程</el-menu-item>
              <el-menu-item index="2-3">订购方式</el-menu-item>
              <el-menu-item index="2-4">常见问题</el-menu-item>
              <el-menu-item index="2-4">帮助中心</el-menu-item>
            </el-sub-menu>
          </li>
        </el-menu>
      </ul>
    </div>
    <!-- 搜索框 -->
    <div class="search_container">
      <a href="/"><img src="../assets/logo-banner.png" alt="logo图标"/></a>
      <div class="inputCon">
        <input type="text" />
        <button>搜索</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "EdiHeader",
    data() {
      return {
        loginToken: false,
        inputName:''
      };
    },
    created() {
    if(window.sessionStorage.getItem("loginToken")) {
      this.loginToken = window.sessionStorage.getItem("loginToken")
      this.inputName = window.sessionStorage.getItem("inputName")
    }
    },
    methods: {

      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      login() {
        this.$router.push("/login");
      },
    },
  };
</script>
<style scoped lang="less">
  .header_container {
    width: 100%;
    // height: 50px;
    background: #fff;

    .nav_container {
      width: 100%;
      line-height: 50px;
      background: rgb(236, 234, 234);

      .left {
        float: left;
        display: flex;
        margin-left: 10%;

        li {
          margin-right: 15px;

          .header-login {
            color: #E31939;
          }

          .header-signup {
            color: #666666;
          }
        }

        li:nth-child(3) {
          color: rgb(168, 158, 158);
        }


      }

      .right {
        float: right;
        display: flex;
        margin-right: 5%;

        li {
          margin-right: 15px;

          .header-cart {
            color: #E31939;
          }
        }

        li:nth-child(2n) {
          color: rgb(168, 158, 158);
        }
      }
    }

    .search_container {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: space-between;
      padding: 0 6% 0 12%;
      align-items: center;
      box-sizing: border-box;

      img {
        height: 50px;
      }

      .inputCon {
        width: 35%;
        height: 30%;
        display: flex;

        input {
          width: 80%;
          height: 100%;
          outline: none;
          padding: 0;
          border: 1px solid #ff4200;
          padding-left: 20px;
          box-sizing: border-box;
        }

        button {
          width: 10%;
          height: 100%;
          padding: 0;
          box-sizing: border-box;
          border: 0;
          background: #ff4200;
          color: #fff;
          display: block;
        }
      }
    }
  }

  .clearfix::after {
    content: "";
    display: block;
    clear: both;
  }

  .el-menu {
    background: transparent;

    .el-sub-menu {
      width: 110px;
    }
  }

  .el-sub-menu .el-sub-menu__icon-arrow {
    position: absolute;
    top: 55%;
    right: 20px;
  }
</style>