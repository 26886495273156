let productList = [{
    productId: "1",
    merchantUser: "众博商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: '1',
    produtItem1: [{
      productGroup: "日用品",
      produtItem: [{
          productId: "1",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/16326497-1db1-4dfd-ac9d-35145da7d10d.jpg",
          productName: "THERMOS膳魔师超轻小巧精致真空保温杯350毫升",
          price: "124",
        },
        {
          productId: "2",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/992ef5a05c524bf8bf5279ad78d7a80c1513736802340jbeft3ws11097.jpg",
          productName: "Nittaya 泰国进口颈椎保健枕 天然乳胶枕头",
          price: "189",
        },
        {
          productId: "3",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/d6d6424837df4d0ab1f8c56b9c812a2c1541260395896jo1mn8hq12972.jpg",
          productName: "德国福腾宝Nuova不锈钢餐具餐刀餐叉勺子4件套",
          price: "122",
        },
        {
          productId: "4",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/4f66ac70b90b469dbc3b7db51af502c41548851897484jrj6fgqh10339.jpg",
          productName: "蜂窝结构食品级不锈钢不粘炒锅 30cm",
          price: "413",
        },
        {
          productId: "5",
          productPic: "https://kaola-pop.oss.kaolacdn.com/9e1cf9dc3d434d4b94f8f23d35d91ea5_800_800.jpg",
          productName: "FUANNA富安娜 蚕丝被 子母被羊毛被 被子冬季冬厚",
          price: "514",
        },
        {
          productId: "6",
          productPic: "https://kaola-pop.oss.kaolacdn.com/78c56c180df84325abef146f4d0cd448_800_800.jpg",
          productName: "五月花700g12卷4层原木无芯卷纸卷筒卫生纸厕纸母婴适用",
          price: "10.9",
        },
        {
          productId: "7",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/4bc90e26-6f69-4b88-bc35-0f91c45dca09.jpg",
          productName: "双枪 家用分色日式合金筷 5双装",
          price: "25.90",
        },
        {
          productId: "8",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/fe2a1931-16ad-4778-83d7-3dc131c07b7d.jpg",
          productName: "Visions透明晶彩锅",
          price: "479 ",
        }, {
          productId: "9",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01NDz4UQ24RIQSMFSqL_!!991137387-0-cib.jpg",
          productName: "漱口杯家用简约刷牙杯子 ",
          price: "9.99 "
        }, {
          productId: "10",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01DD9rac1oLls7VgLps_!!4235095209-0-cib.220x220.jpg?_=2020",
          productName: "厨房速干吸水毛巾日用百货现货 ",
          price: "19.99 "
        }, {
          productId: "11",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01B5kjJi22GBbp55tU1_!!2201413007092-0-cib.jpg",
          productName: "智能可视wifi耳勺黑科技高清无线耳勺掏耳朵 ",
          price: "329 "
        }, {
          productId: "12",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01Qz0Gfk1gQKqWHNLB5_!!2210939434136-0-cib.jpg",
          productName: "创意陶瓷餐具家用活动福利礼品 ",
          price: "14 "
        },
        {
          productId: "13",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01CdCLvJ24RIW1YTW5Y_!!991137387-0-cib.jpg",
          productName: "家用手提大卷新料垃圾袋背心式塑料袋加厚 ",
          price: "19.99 "
        }, {
          productId: "14",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01RCxjwf27ScOi1a1zm_!!2209592367796-0-cib.jpg",
          productName: "厨房纸巾吸油吸水纸用纸食用超强去油擦手纸 ",
          price: "329 "
        }, {
          productId: "15",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01tFrUYL1pHw4okBezC_!!2211227135336-0-cib.jpg",
          productName: "萌萌兔玻璃水杯印刷logo广告杯双层保温 ",
          price: "14 "
        },
        {
          productId: "16",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN019w3ty71Zx7skaNCBH_!!986863260-0-cib.jpg",
          productName: "厨房洗碗布海绵抹布沥水收纳架 ",
          price: "54.99 "
        }, {
          productId: "17",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01qdoBxy1b4HkcjHyHk_!!2213770363411-0-cib.jpg",
          productName: "大豆纤维被子被芯加厚保暖棉 ",
          price: "329 "
        }, {
          productId: "18 ",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01w1lxBI1cRTOMECO7E_!!2212723613597-0-cib.jpg",
          productName: "新款中国风国潮304不锈钢智能保温杯 ",
          price: "145 "
        },
        {
          productId: "19",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01x4Hqok26wYmiNTh2O_!!2200723977726-0-cib.jpg",
          productName: "316不锈钢水杯儿童双饮便携礼品水壶 ",
          price: "65.99 "
        }, {
          productId: "20",
          productPic: "https://cbu01.alicdn.com/img/ibank/2020/149/237/19450732941_2140852738.jpg",
          productName: "304不锈钢真空保温杯 ",
          price: "274 "
        }, {
          productId: "21 ",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN013kXxPU1GLakvZbeUA_!!2213267280606-0-cib.jpg",
          productName: "卡通可爱弹跳保温杯 ",
          price: "345 "
        },
        {
          productId: "22",
          productPic: "https://cbu01.alicdn.com/img/ibank/2016/220/278/3091872022_163598777.jpg",
          productName: "不锈钢保温壶双层真空热水壶 ",
          price: "165 "
        }, {
          productId: "23",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01cJJqXH1PNj78Xhf0j_!!992231829-0-cib.jpg",
          productName: "新款中国风创意保温杯弹跳盖 ",
          price: "179 "
        }, {
          productId: "24 ",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01eJepTo2IHajy5RtJ6_!!2214030919261-0-cib.jpg",
          productName: "微压锅家用网红新款南瓜煲汤锅炖锅 ",
          price: "256 "
        },
      ],
    }, ]
  },
  {
    productId: "2",
    merchantUser: "巨多德商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "2",
    produtItem1: [{
      productGroup: "美妆专区",
      produtItem: [{
          productId: "31",
          productPic: "https://kaola-pop.oss.kaolacdn.com/7fffa8d1ac0b485ebc6da39a94695d3e_800_800.jpg",
          productName: "COSME日本AG抗糖小鸡蛋睡眠面膜抗氧化提亮夜间免洗虾青素",
          price: "406",
        },
        {
          productId: "32",
          productPic: "https://kaola-pop.oss.kaolacdn.com/8ec677b47ea0481ca256eb4a63c269f6_800_800.jpg",
          productName: "海蓝之谜精粹液150ml+雅诗兰黛小棕瓶100ml+倩碧无油黄油125ml 套装滋润修",
          price: "1349",
        },
        {
          productId: "33",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/a2faf0f999a60c65e6ccb4ab29600468.jpg",
          productName: "欧莱雅复颜玻尿酸套装（洁面+晶露+乳液+零点霜 +眼霜）",
          price: "1146",
        },
        {
          productId: "34",
          productPic: "https://kaola-pop.oss.kaolacdn.com/7419eecb8fd343be8e24098dab311028_800_800.jpg",
          productName: "【专利类肉多肽+角鲨烷】Valion肽美弹润多效焕颜晚霜补水紧致修复抗氧",
          price: "589",
        },
        {
          productId: "35",
          productPic: "https://kaola-pop.oss.kaolacdn.com/11b60de1113c4535a4f8f721a42b750e_800_800.jpg",
          productName: "韩国JAYJUN捷俊樱花面膜水光焕亮贴片面膜10片/盒+樱花睡眠面膜20条",
          price: "199",
        },
        {
          productId: "36",
          productPic: "https://kaola-pop.oss.kaolacdn.com/fabb59c70b48481da93a5e41393769e5_800_800.jpg",
          productName: "日本资生堂六角眉笔1.2g/支 *2 新手易上装防水防汗硬头易上色持久不晕染",
          price: "199",
        },
        {
          productId: "37",
          productPic: "https://kaola-pop.oss.kaolacdn.com/be2def5feb8e4808960430c23f3ba8db_800_800.jpg",
          productName: "洗脸巾-40抽",
          price: "39",
        },
        {
          productId: "42",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01MRMRW01vwayAUwCIW_!!2209237906237-0-cib.jpg",
          productName: "四色眼影盘珠光哑光闪粉防水小盘便携",
          price: "134"
        }, {
          productId: "43",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01yJXH7122DRGAIXD2R_!!2206683937086-0-cib.jpg",
          productName: "当潮彩妆套装全套气垫口红眼影盘遮瑕膏礼盒",
          price: "268"
        }, {
          productId: "44",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01kmne38208HXT6gUIh_!!2979316804-0-cib.jpg",
          productName: "闪亮自动卧蚕笔下至珠光提亮眼影高光笔",
          price: "199"
        },
        {
          productId: "45",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN017fXIYz1nvCqcNXJCt_!!3342045151-0-cib.jpg",
          productName: "蜂巢六角星钻眼影盘芋泥粉蜂蜜大地眼影",
          price: "99"
        },
        {
          productId: "46",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01wRb9qP1oEtumT9Nr1_!!2210902695194-0-cib.jpg",
          productName: "液体眼影哑光质地奶油慕斯眼影液",
          price: "89"
        },
        {
          productId: "47",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01MkpkpM1QEOiKi41vp_!!2211145761944-0-cib.jpg",
          productName: "bb霜cc霜遮瑕持妆不卡粉不脱妆裸妆",
          price: "269"
        },
        {
          productId: "48",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN011Ml6HI1R7oYfTzhug_!!2878522065-0-cib.jpg",
          productName: "BB霜遮瑕保湿哑光雾面防汗水不易脱妆",
          price: "199"
        }, {
          productId: "49",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN012IGly51Jc1p27q4x4_!!2207625151048-0-cib.jpg",
          productName: "散粉定妆粉遮瑕持久修容打底粉防水防汗",
          price: "189"
        },
        {
          productId: "50",
          productPic: "https://cbu01.alicdn.com/img/ibank/2020/609/836/23010638906_1540556962.jpg",
          productName: "超柔软全套美妆工具散粉眼影刷支持代发",
          price: "69"
        },
        {
          productId: "51",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01e0O6ha1QEOfQm5gB8_!!2211145761944-0-cib.jpg",
          productName: "隐秘毛孔遮瑕通用蜜粉超细柔滑定妆粉",
          price: "99"
        }, {
          productId: "52",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01llongD1R7ocDsEmxD_!!2878522065-0-cib.jpg",
          productName: "轻盈丝滑空气定妆粉遮瑕控油防水防汗不易脱妆散粉",
          price: "189"
        },
        {
          productId: "53",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01nz01qy1nAwuiLCKcC_!!2214670585050-0-cib.jpg",
          productName: "玻尿酸补水护肤六件套保湿滋养修护",
          price: "299"
        },
        {
          productId: "54",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01qZ2SFC1gchbGg5yal_!!3440594163-0-cib.jpg",
          productName: "十件套补水保湿护肤品品牌套装化妆品水乳套盒",
          price: "379"
        }, {
          productId: "55",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01QG2vR21WC0LqMNLLW_!!2212608942751-0-cib.jpg",
          productName: "轻盈丝滑空气定妆粉遮瑕控油防水防汗不易脱妆散粉",
          price: "89"
        },
        {
          productId: "56",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN016JAJMk1ofSuWYxmwU_!!2208108465252-0-cib.jpg",
          productName: "七件套滋养补水保湿滋润面部护肤套盒",
          price: "539"
        },
      ],
    }, ]
  },
  {
    productId: "3",
    merchantUser: "佳选商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "3",
    produtItem1: [{
      productGroup: "服装品",
      produtItem: [{
          productId: "61",
          productPic: "https://kaola-pop.oss.kaolacdn.com/e514e3b1933c4ec59e97dcd888038243_800_800.jpg",
          productName: "新款男士领边不对称印花短袖polo衫 美码偏大一码",
          price: "155",
        },
        {
          productId: "62",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/c3007574184ae05defacd28096eb4d25.jpg",
          productName: "MLB男女复古老爹鞋帆布鞋小标增高运动鞋21牛年春季新款32SHU2",
          price: "695",
        },
        {
          productId: "63",
          productPic: "https://kaola-pop.oss.kaolacdn.com/17ea3316c882482b85cd5ca88cea869b_800_800.jpg",
          productName: "OL线标配 精致柜面感 质感白色修身连衣裙",
          price: "345",
        },
        {
          productId: "64",
          productPic: "https://kaola-pop.oss.kaolacdn.com/3825d69b6bf54c7682a12a0611d00c80_800_800.jpg",
          productName: "春夏季新款女板鞋 低帮百搭时尚女休闲鞋 舒适内增高女单鞋",
          price: "325",
        },
        {
          productId: "65",
          productPic: "https://kaola-pop.oss.kaolacdn.com/c77c33638c0c43c382950206a289e96d_800_800.jpg",
          productName: "polo衫短袖男装经典刺绣标翻领商务休闲上衣短袖男士夏季新",
          price: "329",
        }, {
          productId: "66",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01JSpmv11niqDuZn02G_!!2214147965124-0-cib.jpg",
          productName: "小香风外套气质淑女秋冬女装新款短上衣",
          price: "235"
        },
        {
          productId: "67",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01M4eDvX1K90MXh3gAl_!!2214340551120-0-cib.jpg",
          productName: "新款小众设计收腰显瘦高级感裙子",
          price: "125"
        },
        {
          productId: "68",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01LzUACE1K90N6tTfDn_!!2214340551120-0-cib.jpg",
          productName: "新款复古设计感小个子长裙子",
          price: "329"
        }, {
          productId: "67",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01rotjlk1oz9uhQzplj_!!998505295-0-cib.jpg",
          productName: " 纯欲花边设计感裙子仙气显瘦连衣裙",
          price: "156"
        },
        {
          productId: "68",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01cIpFKL1QWiZ9lpWL9_!!2214083201984-0-cib.jpg",
          productName: "polo半拉链简约大气卫连衣裙",
          price: "190"
        },
        {
          productId: "69",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN015G7GDI1oz9viuJUoa_!!998505295-0-cib.jpg",
          productName: "欧尼的挂脖连衣裙套组 慵懒气质挂",
          price: "229"
        },
        {
          productId: "70",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01LzUACE1K90N6tTfDn_!!2214340551120-0-cib.jpg",
          productName: " 新款复古设计感小个子长裙子",
          price: "386"
        },
        {
          productId: "71",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01alFEg922FjH7myLXf_!!2208158007091-0-cib.jpg",
          productName: "日系娃娃领长袖连衣裙女中长款早秋新款",
          price: "270"
        },
        {
          productId: "72",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01mmfyaD29FSGU61ddj_!!2841768038-0-cib.jpg",
          productName: "新款秋冬加绒束脚卫裤显瘦阔腿裤",
          price: "129"
        }, {
          productId: "73",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01or4UjA29FSLypaP8p_!!2841768038-0-cib.jpg",
          productName: " 新款休闲卫裤宽松小个子抽绳哈伦运动裤",
          price: "99"
        },
        {
          productId: "74",
          productPic: "https://cbu01.alicdn.com/img/ibank/2019/290/896/12398698092_107751685.jpg",
          productName: "欧美高跟鞋女鞋细跟高跟",
          price: "610"
        },
        {
          productId: "75",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01PFweVJ2Em2VVWeh54_!!2209651778786-0-cib.jpg",
          productName: "新款碎花少女浅口中空高跟鞋女细跟",
          price: "429"
        }, {
          productId: "76",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01NtgzO81JHsPCirpR0_!!2214064121004-0-cib.jpg",
          productName: " 真皮羊皮透明女凉鞋粗高跟鞋夏季",
          price: "299"
        },
        {
          productId: "77",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01T6rH2S2AZRFAPLgfi_!!2208523998217-0-cib.jpg",
          productName: "秀禾白色婚纱两穿珍珠新娘鞋蝴蝶结",
          price: "390"
        },
        {
          productId: "78",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01chDOsK2GurTP3SDjq_!!2214110129076-0-cib.jpg",
          productName: "真皮老爹鞋女ins潮2022秋冬季新款",
          price: "229"
        }, {
          productId: "79",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01Ie6OI82KWp8O7oSEh_!!2200616459565-0-cib.jpg",
          productName: " 厚底老爹鞋女秋季新款学生百搭运动女鞋",
          price: "239"
        },
      ],
    }, ]
  }, {
    productId: "4",
    merchantUser: "合家乐商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "4",
    produtItem1: [{
      productGroup: "服装品",
      produtItem: [{
        productId: "97",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01YLTgYm1bycTXawZhA_!!3642503534-0-cib.jpg",
        productName: "爬服哈衣外出抱衣加厚新生儿衣服冬",
        price: "369",
      },{
        productId: "96",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01VAzUoQ1nMr9z1eCFG_!!2861935076-0-cib.jpg",
        productName: "春秋冬新品宝宝卡通男女婴童纯棉小熊长袖连身衣爬服",
        price: "249",
      },
      {
        productId: "95",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN013qH2ga1fkerIuvSRa_!!2206579574045-0-cib.jpg",
        productName: " 哈衣爬服春秋婴童服饰秋季秋装",
        price: "179"
      },
      {
        productId: "80",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01nOK7Sb1a3ztB0WilO_!!2212773053275-0-cib.jpg",
        productName: "秋季新款松糕女鞋厚底百搭休闲板鞋",
        price: "190"
      },
      {
        productId: "81",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01BG25At279NrfMgwKZ_!!2207635897754-0-cib.jpg",
        productName: "网红老爹鞋女ins潮休闲增高厚底运动鞋",
        price: "389"
      }, {
        productId: "82",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01rZxi6A2Ff08jowlJN_!!2214688668906-0-cib.jpg",
        productName: " 内增高厚底老爹情侣鞋莆田男潮鞋",
        price: "319"
      },
      {
        productId: "83",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01k2ZM8X1FZxbexorNT_!!2214598300502-0-cib.jpg",
        productName: "秋装男女宝宝韩版长袖连体衣新生儿外出衣服时",
        price: "99"
      },
      {
        productId: "84",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01kofoJC1OAcASXTPa8_!!4179991665-0-cib.jpg",
        productName: "新生儿衣服春秋哈衣长袖连体幼童蝴蝶衣",
        price: "189"
      }, {
        productId: "85",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01Ho7SSJ1GTNWLBrgUJ_!!1008420623-0-cib.jpg",
        productName: " 秋装连体衣宝宝超洋气撞色哈衣外出爬爬服",
        price: "239"
      },
      {
        productId: "86",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/341/610/20813016143_152299861.jpg",
        productName: "0-2岁秋装宝宝卡通工装爬服婴儿衣服",
        price: "279"
      },
      {
        productId: "87",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01yR6zfe1bycTRDTXEt_!!3642503534-0-cib.jpg",
        productName: "婴儿衣服秋男女宝宝春秋长袖针织连体衣",
        price: "219"
      }, {
        productId: "88",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN013qH2ga1fkerIuvSRa_!!2206579574045-0-cib.jpg",
        productName: " 哈衣爬服春秋婴童服饰秋季秋装",
        price: "179"
      },
      {
        productId: "89",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01VAzUoQ1nMr9z1eCFG_!!2861935076-0-cib.jpg",
        productName: "春秋冬新品宝宝卡通男女婴童纯棉小熊长袖连身衣爬服",
        price: "249"
      },
      {
        productId: "90",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01YLTgYm1bycTXawZhA_!!3642503534-0-cib.jpg",
        productName: "爬服哈衣外出抱衣加厚新生儿衣服冬",
        price: "369"
      },
      {
        productId: "91",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01kofoJC1OAcASXTPa8_!!4179991665-0-cib.jpg",
        productName: "新生儿衣服春秋哈衣长袖连体幼童蝴蝶衣",
        price: "189"
      }, {
        productId: "92",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01Ho7SSJ1GTNWLBrgUJ_!!1008420623-0-cib.jpg",
        productName: " 秋装连体衣宝宝超洋气撞色哈衣外出爬爬服",
        price: "239"
      },
      {
        productId: "93",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/341/610/20813016143_152299861.jpg",
        productName: "0-2岁秋装宝宝卡通工装爬服婴儿衣服",
        price: "279"
      },
      {
        productId: "94",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01yR6zfe1bycTRDTXEt_!!3642503534-0-cib.jpg",
        productName: "婴儿衣服秋男女宝宝春秋长袖针织连体衣",
        price: "219"
      }
     ]
    }]
  },
  {
    productId: "5",
    merchantUser: "美宜购商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "5",
    produtItem1: [{
      productGroup: "美妆专区",
      produtItem: [{
        productId: "57",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN016JAJMk1ofSuWYxmwU_!!2208108465252-0-cib.jpg",
        productName: "七件套滋养补水保湿滋润面部护肤套盒套",
        price: "379"
      }, {
        productId: "58",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN014Md2Jr1WC0M3oSG2N_!!2212608942751-0-cib.jpg",
        productName: "轻盈丝滑空气定妆粉遮瑕控油防水防汗不易脱妆散粉",
        price: "89"
      },
      {
        productId: "59",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN016JAJMk1ofSuWYxmwU_!!2208108465252-0-cib.jpg",
        productName: "玻尿酸补水喷雾保湿修护精华清凉爽肤水",
        price: "89"
      },
      {
        productId: "60",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/274/782/20904287472_731122973.jpg",
        productName: "水润保湿补水清洁水乳面霜眼霜套盒面部焕彩护肤五件套",
        price: "279"
      }, {
        productId: "100",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01e0O6ha1QEOfQm5gB8_!!2211145761944-0-cib.jpg",
        productName: "隐秘毛孔遮瑕通用蜜粉超细柔滑定妆粉",
        price: "99"
      }, {
        productId: "101",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01llongD1R7ocDsEmxD_!!2878522065-0-cib.jpg",
        productName: "轻盈丝滑空气定妆粉遮瑕控油防水防汗不易脱妆散粉",
        price: "189"
      },
      {
        productId: "102",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01nz01qy1nAwuiLCKcC_!!2214670585050-0-cib.jpg",
        productName: "玻尿酸补水护肤六件套保湿滋养修护",
        price: "299"
      },
      {
        productId: "103",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01qZ2SFC1gchbGg5yal_!!3440594163-0-cib.jpg",
        productName: "十件套补水保湿护肤品品牌套装化妆品水乳套盒",
        price: "379"
      }, {
        productId: "104",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01QG2vR21WC0LqMNLLW_!!2212608942751-0-cib.jpg",
        productName: "轻盈丝滑空气定妆粉遮瑕控油防水防汗不易脱妆散粉",
        price: "89"
      },
      {
        productId: "105",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN016JAJMk1ofSuWYxmwU_!!2208108465252-0-cib.jpg",
        productName: "七件套滋养补水保湿滋润面部护肤套盒",
        price: "539"
      },
      {
        productId: "106",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN016JAJMk1ofSuWYxmwU_!!2208108465252-0-cib.jpg",
        productName: "七件套滋养补水保湿滋润面部护肤套盒套",
        price: "379"
      }, {
        productId: "107",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN014Md2Jr1WC0M3oSG2N_!!2212608942751-0-cib.jpg",
        productName: "轻盈丝滑空气定妆粉遮瑕控油防水防汗不易脱妆散粉",
        price: "89"
      },
      {
        productId: "108",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN016JAJMk1ofSuWYxmwU_!!2208108465252-0-cib.jpg",
        productName: "玻尿酸补水喷雾保湿修护精华清凉爽肤水",
        price: "89"
      },
      {
        productId: "109",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/274/782/20904287472_731122973.jpg",
        productName: "水润保湿补水清洁水乳面霜眼霜套盒面部焕彩护肤五件套",
        price: "279"
      } ]
    }]
  }, {
    productId: "6",
    merchantUser: "洋悦商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "6",
    produtItem1: [{
      productGroup: "美妆专区",
      produtItem: [{
        productId: "33",
        productPic: "https://kaola-haitao.oss.kaolacdn.com/a2faf0f999a60c65e6ccb4ab29600468.jpg",
        productName: "欧莱雅复颜玻尿酸套装（洁面+晶露+乳液+零点霜 +眼霜）",
        price: "1146"
      },
      {
        productId: "34",
        productPic: "https://kaola-pop.oss.kaolacdn.com/7419eecb8fd343be8e24098dab311028_800_800.jpg",
        productName: "【专利类肉多肽+角鲨烷】Valion肽美弹润多效焕颜晚霜补水紧致修复抗氧",
        price: "589"
      },
      {
        productId: "35",
        productPic: "https://kaola-pop.oss.kaolacdn.com/11b60de1113c4535a4f8f721a42b750e_800_800.jpg",
        productName: "韩国JAYJUN捷俊樱花面膜水光焕亮贴片面膜10片/盒+樱花睡眠面膜20条",
        price: "199"
      },
      {
        productId: "36",
        productPic: "https://kaola-pop.oss.kaolacdn.com/fabb59c70b48481da93a5e41393769e5_800_800.jpg",
        productName: "日本资生堂六角眉笔1.2g/支 *2 新手易上装防水防汗硬头易上色持久不晕染",
        price: "199"
      },
      {
        productId: "37",
        productPic: "https://kaola-pop.oss.kaolacdn.com/be2def5feb8e4808960430c23f3ba8db_800_800.jpg",
        productName: "洗脸巾-40抽",
        price: "39"
      },
      {
        productId: "37",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01uWRE9R1Jc1qAJM9Ka_!!2207625151048-0-cib.jpg",
        productName: "国风七件套装彩妆美妆全套",
        price: "238"
      },
      {
        productId: "38",
        productPic: "https://cbu01.alicdn.com/img/ibank/2019/910/635/10408536019_1170480298.jpg",
        productName: "中式醉鸾中国风口红套装",
        price: "169"
      },
      {
        productId: "39",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01deyGG81PLR9ZiWnvq_!!2648241824-0-cib.jpg",
        productName: "丝绒细管口红套装丝绒雾面哑光",
        price: "298"
      }, {
        productId: "40",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01chx3Js26lZFi5zeyr_!!3994217702-0-cib.jpg",
        productName: "18色沙漠玫瑰眼影盘哑珠光大地色",
        price: "138"
      },
      {
        productId: "41",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/991/840/20131048199_1170480298.jpg",
        productName: "宫廷雕花口红套装",
        price: "299"
      },]
    }]
  },
  {
    productId: "7",
    merchantUser: "万家客商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "7",
    produtItem1: [{
      productGroup: "日用品",
      produtItem: [{
        productId: "124",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN019w3ty71Zx7skaNCBH_!!986863260-0-cib.jpg",
        productName: "厨房洗碗布海绵抹布沥水收纳架 ",
        price: "54.99 ",
      }, {
        productId: "125",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01qdoBxy1b4HkcjHyHk_!!2213770363411-0-cib.jpg",
        productName: "大豆纤维被子被芯加厚保暖棉 ",
        price: "329 ",
      }, {
        productId: "126",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01w1lxBI1cRTOMECO7E_!!2212723613597-0-cib.jpg",
        productName: "新款中国风国潮304不锈钢智能保温杯 ",
        price: "145 "
      },
      {
        productId: "127",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01x4Hqok26wYmiNTh2O_!!2200723977726-0-cib.jpg",
        productName: "316不锈钢水杯儿童双饮便携礼品水壶 ",
        price: "65.99 "
      }, {
        productId: "128",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/149/237/19450732941_2140852738.jpg",
        productName: "304不锈钢真空保温杯 ",
        price: "274 "
      }, {
        productId: "129",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN013kXxPU1GLakvZbeUA_!!2213267280606-0-cib.jpg",
        productName: "卡通可爱弹跳保温杯 ",
        price: "345 "
      },
      {
        productId: "130",
        productPic: "https://cbu01.alicdn.com/img/ibank/2016/220/278/3091872022_163598777.jpg",
        productName: "不锈钢保温壶双层真空热水壶 ",
        price: "165 "
      }, {
        productId: "131",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01cJJqXH1PNj78Xhf0j_!!992231829-0-cib.jpg",
        productName: "新款中国风创意保温杯弹跳盖 ",
        price: "179 "
      }, {
        productId: "132",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01eJepTo2IHajy5RtJ6_!!2214030919261-0-cib.jpg",
        productName: "微压锅家用网红新款南瓜煲汤锅炖锅 ",
        price: "256 "
      },
      {
        productId: "133",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01iGD1eD1XHKYOTLkiC_!!2212825482898-0-cib.jpg",
        productName: "韩式火锅烤肉涮烤一体机锅多功能家用电锅 ",
        price: "295 "
      }, {
        productId: "134",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01NPQfV71Mr5jQpveCO_!!2201422021487-0-cib.jpg",
        productName: "全自动多功能锅批发三层大容量 ",
        price: "235 "
      }, {
        productId: "135",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01bsSnI01bFHCrxJ3UV_!!2207712593435-0-cib.jpg",
        productName: "大容量多层电蒸锅商用多功能304不锈钢蒸笼 ",
        price: "385 "
      }]
    }]
  }, {
    productId: "8",
    merchantUser: "华隆商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "8",
    produtItem1: [{
      productGroup: "日用品",
      produtItem: [
        {
          productId: "30 ",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01m4DN8o1xIrulhxsvk_!!2213328816421-0-cib.jpg",
          productName: "家用饭碗蒸鱼盘子菜盘金边餐具 ",
          price: "265 ",
          mallId: '15',
          mallName: '华隆商城'
        }, {
          productId: "110",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/992ef5a05c524bf8bf5279ad78d7a80c1513736802340jbeft3ws11097.jpg",
          productName: "Nittaya 泰国进口颈椎保健枕 天然乳胶枕头",
          price: "189"
        },
        {
          productId: "111",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/d6d6424837df4d0ab1f8c56b9c812a2c1541260395896jo1mn8hq12972.jpg",
          productName: "德国福腾宝Nuova不锈钢餐具餐刀餐叉勺子4件套",
          price: "122"
        },
        {
          productId: "112",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/4f66ac70b90b469dbc3b7db51af502c41548851897484jrj6fgqh10339.jpg",
          productName: "蜂窝结构食品级不锈钢不粘炒锅 30cm",
          price: "413"
        },
        {
          productId: "113",
          productPic: "https://kaola-pop.oss.kaolacdn.com/9e1cf9dc3d434d4b94f8f23d35d91ea5_800_800.jpg",
          productName: "FUANNA富安娜 蚕丝被 子母被羊毛被 被子冬季冬厚",
          price: "514"
        },
        {
          productId: "114",
          productPic: "https://kaola-pop.oss.kaolacdn.com/78c56c180df84325abef146f4d0cd448_800_800.jpg",
          productName: "五月花700g12卷4层原木无芯卷纸卷筒卫生纸厕纸母婴适用",
          price: "10.9",
        },
        {
          productId: "115",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/4bc90e26-6f69-4b88-bc35-0f91c45dca09.jpg",
          productName: "双枪 家用分色日式合金筷 5双装",
          price: "25.90"
        },
        {
          productId: "116",
          productPic: "https://kaola-haitao.oss.kaolacdn.com/fe2a1931-16ad-4778-83d7-3dc131c07b7d.jpg",
          productName: "Visions透明晶彩锅",
          price: "479 "
        }, {
          productId: "117",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01NDz4UQ24RIQSMFSqL_!!991137387-0-cib.jpg",
          productName: "漱口杯家用简约刷牙杯子 ",
          price: "9.99 "
        }, {
          productId: "118",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01DD9rac1oLls7VgLps_!!4235095209-0-cib.220x220.jpg?_=2020",
          productName: "厨房速干吸水毛巾日用百货现货 ",
          price: "19.99 "
        }, {
          productId: "119",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01B5kjJi22GBbp55tU1_!!2201413007092-0-cib.jpg",
          productName: "智能可视wifi耳勺黑科技高清无线耳勺掏耳朵 ",
          price: "329 "
        }, {
          productId: "120",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01Qz0Gfk1gQKqWHNLB5_!!2210939434136-0-cib.jpg",
          productName: "创意陶瓷餐具家用活动福利礼品 ",
          price: "14 "
        },
        {
          productId: "121",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01CdCLvJ24RIW1YTW5Y_!!991137387-0-cib.jpg",
          productName: "家用手提大卷新料垃圾袋背心式塑料袋加厚 ",
          price: "19.99 "
        }, {
          productId: "123",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01RCxjwf27ScOi1a1zm_!!2209592367796-0-cib.jpg",
          productName: "厨房纸巾吸油吸水纸用纸食用超强去油擦手纸 ",
          price: "329 "
        }, {
          productId: "122",
          productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01tFrUYL1pHw4okBezC_!!2211227135336-0-cib.jpg",
          productName: "萌萌兔玻璃水杯印刷logo广告杯双层保温 ",
          price: "14 "
        },
      ]
    }]
  }, {
    productId: "9",
    merchantUser: "优嘉商城",
    merchantGrade: "初级店铺",
    merchantTime: "2022-5-6",
    mallId: "9",
    produtItem1: [{
      productGroup: "日用品",
      produtItem: [{
        productId: "16",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN019w3ty71Zx7skaNCBH_!!986863260-0-cib.jpg",
        productName: "厨房洗碗布海绵抹布沥水收纳架 ",
        price: "54.99 "
      }, {
        productId: "17",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01qdoBxy1b4HkcjHyHk_!!2213770363411-0-cib.jpg",
        productName: "大豆纤维被子被芯加厚保暖棉 ",
        price: "329 "
      }, {
        productId: "18 ",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01w1lxBI1cRTOMECO7E_!!2212723613597-0-cib.jpg",
        productName: "新款中国风国潮304不锈钢智能保温杯 ",
        price: "145 "
      },
      {
        productId: "19",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01x4Hqok26wYmiNTh2O_!!2200723977726-0-cib.jpg",
        productName: "316不锈钢水杯儿童双饮便携礼品水壶 ",
        price: "65.99 "
      }, {
        productId: "20",
        productPic: "https://cbu01.alicdn.com/img/ibank/2020/149/237/19450732941_2140852738.jpg",
        productName: "304不锈钢真空保温杯 ",
        price: "274 "
      }, {
        productId: "21 ",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN013kXxPU1GLakvZbeUA_!!2213267280606-0-cib.jpg",
        productName: "卡通可爱弹跳保温杯 ",
        price: "345 "
      },
      {
        productId: "22",
        productPic: "https://cbu01.alicdn.com/img/ibank/2016/220/278/3091872022_163598777.jpg",
        productName: "不锈钢保温壶双层真空热水壶 ",
        price: "165 "
      }, {
        productId: "23",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01cJJqXH1PNj78Xhf0j_!!992231829-0-cib.jpg",
        productName: "新款中国风创意保温杯弹跳盖 ",
        price: "179 "
      }, {
        productId: "24 ",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01eJepTo2IHajy5RtJ6_!!2214030919261-0-cib.jpg",
        productName: "微压锅家用网红新款南瓜煲汤锅炖锅 ",
        price: "256 "
      },
      {
        productId: "25",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01iGD1eD1XHKYOTLkiC_!!2212825482898-0-cib.jpg",
        productName: "韩式火锅烤肉涮烤一体机锅多功能家用电锅 ",
        price: "295 "
      }, {
        productId: "26",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01NPQfV71Mr5jQpveCO_!!2201422021487-0-cib.jpg",
        productName: "全自动多功能锅批发三层大容量 ",
        price: "235 "
      }, {
        productId: "27 ",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01bsSnI01bFHCrxJ3UV_!!2207712593435-0-cib.jpg",
        productName: "大容量多层电蒸锅商用多功能304不锈钢蒸笼 ",
        price: "385 "
      },
      {
        productId: "28",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01dT21tR1TzWHLqyQIm_!!2209278682453-0-cib.jpg",
        productName: "不锈钢刀叉勺四件套西餐具 ",
        price: "195 "
      }, {
        productId: "29",
        productPic: "https://cbu01.alicdn.com/img/ibank/O1CN01htjmwZ1Slwytfh8Zf_!!2201289512288-0-cib.jpg",
        productName: "原生木浆干厕纸易溶不堵马桶 ",
        price: "35 "
      }, ]
    }]
  },
]
export default productList;