<template>
  <div class="log">
    <div class="log_tit">
      <div class="log_log">
        <a href="/" ><img class="header-logo" src="../../assets/logo-banner.png"></a>
      </div>
      <h2 class="log_text">免费注册</h2>

    </div>
    <div class="log_banner">
      <div class="log_relative">
        <div class="log_form">
          <div class="log_signup">
            <h3>手机注册</h3>
            <h2 class="log_text">
              <router-link class="header-signup" to="/login">用户登录</router-link>
            </h2>
          </div>
          <el-form ref="formLabelAlign" :model="formLabelAlign" status-icon class="demo-ruleForm">
            <el-form-item>
              <el-input v-model="formLabelAlign.inputName" prefix-icon="iphone" placeholder="手机号" width="300px"
                height="40px" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="formLabelAlign.inputPasswode1" type="password" prefix-icon="lock" placeholder="密码"
                show-password />
            </el-form-item>
            <el-form-item>
              <el-input v-model="formLabelAlign.inputPasswode2" type="password" prefix-icon="lock" show-password
                placeholder="确认密码" />
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="formLabelAlign.checked2"></el-checkbox>
              <p>我已看过并接受《 <router-link class="header-signup" to="/article">服务协议</router-link>》《<router-link
                  class="header-signup" to="/privacy">隐私政策</router-link>》</p>
            </el-form-item>
            <el-form-item>
               <el-button
                class="log_btn"
                type="primary"
                :disabled = '!formLabelAlign.checked2'
                @click="submitForm('formLabelAlign')"
              >注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EdiSignuo",
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formLabelAlign.inputPasswode1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      formLabelAlign: {
        inputName: "",
        inputPasswode1: "",
        inputPasswode2: "",
        checked2: true,
      },
      rules: {
        inputPasswode1: [
          { validator: validatePass, trigger: 'blur' }
        ],
        inputPasswode2: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        inputName: [
          { validator: checkName, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formLabelAlign.validate((valid) => {
        if (valid) {
          console.log(this.formLabelAlign)
          window.sessionStorage.setItem('inputName', this.formLabelAlign.inputName)
          window.sessionStorage.setItem('inputPasswode', this.formLabelAlign.inputPasswode1)
          this.$message({
            message:'注册成功',
            type:'success'
          })
          this.$router.push('/')
        } else {
          return false;
        }
      });
      
    }
  }
}
</script>
<style scoped lang="less">
  .log {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    padding-bottom: 70px;
  }

  .log_tit {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .log_tit .log_log {
    width: 290px;
    height: 70px;
    text-align: center;
    line-height: 70px;
  }

  .log_tit .log_text {
    border-left: 1px #eee solid;
    width: 290px;
    height: 44px;
    line-height: 44px;
    font-size: 28px;
    margin: 26px 0px 0px 15px;
    padding: 0px 15px;
  }

  .log_banner {
    height: 470px;
    margin: 20px auto 0;
  }

  .log_relative {
    width: 1000px;
    height: 470px;
    margin: 0 auto;
    position: relative;
  }

  .log_form {
    overflow: hidden;
    border: 1px #ddd solid;
    width: 300px;
    min-height: 355px;
    height: auto;
    position: absolute;
    z-index: 1;
    width: 306px;
    background: #fff;
    padding: 20px;
    overflow: visible;
    top: 40px;
    right: 0;
    .log_signup{
      display: flex;
      justify-content: space-between;
    }
  }

  .el-form-item {
    margin: 30px auto;
  }

  .log_form p {
    margin-left: 5px;
    color: #333;
  }

  .log_form p a {
    color: blue;
  }

  .log_btn {
    width: 300px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: red;
    color: #fff;
    font-size: 20px;
    border-radius: 0;
    border: none;
  }
  .header-logo{
    width: 290px;
  }
</style>