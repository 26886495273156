<template>
  <!-- nav -->
  <div class="aricle">
    <h2>用户协议</h2>
    <h4>注册服务条款</h4>
    <p>尊敬的用户，欢迎您注册成为本网站用户。在注册前请您仔细阅读如下服务条款：本服务协议双方为本网站与本网站用户，本服务协议具有合同效力。您确认本服务协议后，本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"注册"按钮并按照本网站注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议。
    </p>
    <div
      v-for="(itemGroup, index) in productList"
      :key="index"
    >
      <h5>{{itemGroup.productGroup}}</h5>
      <div
        class="productName"
        v-for="(item, index) in itemGroup.produtItem"
        :key="index"
      >{{item.productName}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EdiArticle",
  data() {
    return {
      productList: [
        {
          productGroup: "1．本网站服务条款的确认和接纳",
          produtItem: [
            {
              productName: "本网站各项服务的所有权和运作权归本网站拥有。",
            },
          ],
        },
        {
          productGroup: "2．用户必须：",
          produtItem: [
            {
              productName:
                "(1)自行配备上网的所需设备， 包括个人电脑、调制解调器或其他必备上网装置。",
            },
            {
              productName:
                "(2)自行负担个人上网所支付的与此服务有关的电话费用、 网络费用。",
            },
          ],
        },
        {
          productGroup: "3．用户在本网站上交易平台上不得发布下列违法信息：",
          produtItem: [
            {
              productName: "(1)反对宪法所确定的基本原则的；",
            },
            {
              productName:
                "(2).危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；",
            },
            {
              productName: "(3).损害国家荣誉和利益的；",
            },
            {
              productName: "(4).煽动民族仇恨、民族歧视，破坏民族团结的；",
            },
            {
              productName: "(5).破坏国家宗教政策，宣扬邪教和封建迷信的；",
            },
            {
              productName: "(6).散布谣言，扰乱社会秩序，破坏社会稳定的；",
            },
            {
              productName:
                "(7).散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；",
            },
            {
              productName: "(8).侮辱或者诽谤他人，侵害他人合法权益的；",
            },
            {
              productName: "(9).含有法律、行政法规禁止的其他内容的。",
            },
          ],
        },
        {
          productGroup: "4． 有关个人资料",
          produtItem: [
            {
              productName: "(1) 提供及时、详尽及准确的个人资料。",
            },
            {
              productName: "(2).同意接收来自本网站的信息。",
            },
            {
              productName:
                "(3) 不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。",
            },
            {
              productName:
                "(4)本网站不公开用户的姓名、地址、电子邮箱和笔名，以下情况除外：",
            },
            {
              productName: "（a）用户授权本网站透露这些信息。",
            },
            {
              productName:
                "（b）相应的法律及程序要求本网站提供用户的个人资料。如果用户提供的资料包含有不正确的信息，本网站保留结束用户使用本网站信息服务资格的权利。",
            },
          ],
        },
        {
          productGroup:
            "5. 用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本网站发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常接收或阅读的，只要本网站成功发送了电子邮件，应当视为用户已经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。",
        },
        {
          productGroup: "6． 服务条款的修改",
          produtItem: [
            {
              productName:
                "本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改或中断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。",
            },
          ],
        },
        {
          productGroup: "7． 用户隐私制度",
          produtItem: [
            {
              productName:
                "尊重用户个人隐私是本网站的一项基本政策。所以，本网站一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本网站中的非公开内容，除非有法律许可要求或本网站在诚信的基础上认为透露这些信息在以下四种情况是必要的：",
            },
            {
              productName: "(1) 遵守有关法律规定，遵从本网站合法服务程序。",
            },
            {
              productName: "(2) 保持维护本网站的商标所有权。",
            },
            {
              productName:
                "(3) 在紧急情况下竭力维护用户个人和社会大众的隐私安全。",
            },
            {
              productName: "(4)符合其他相关的要求。",
            },
            {
              productName: "本网站保留发布会员人口分析资询的权利。",
            },
          ],
        },
        {
          productGroup: "8．用户的帐号、密码和安全性",
          produtItem: [
            {
              productName:
                "你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。你可随时根据指示改变你的密码，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通告本网站。",
            },
          ],
        },
        {
          productGroup: "9． 拒绝提供担保",
          produtItem: [
            {
              productName:
                "用户明确同意信息服务的使用由用户个人承担风险。 本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。",
            },
          ],
        },
        {
          productGroup: "10．有限责任",
          produtItem: [
            {
              productName:
                "本网站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用本网站服务，或用户传送的信息不符合规定等。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。",
            },
          ],
        },
        {
          productGroup: "11．信息的储存及限制",
          produtItem: [
            {
              productName:
                "本网站有判定用户的行为是否符合本网站服务条款的要求和精神的权利，如果用户违背本网站服务条款的规定，本网站有权中断其服务的帐号。",
            },
          ],
        },
        {
          productGroup: "12．用户管理",
          produtItem: [
            {
              productName: "用户必须遵循：",
            },
            {
              productName: "(1) 使用信息服务不作非法用途。",
            },
            {
              productName: "(2) 不干扰或混乱网络服务。",
            },
            {
              productName:
                "(3) 遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。",
            },
          ],
        },
        {
          productGroup: "13．保障",
          produtItem: [
            {
              productName:
                "用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。",
            },
          ],
        },
      ],
    };
  },
  components: {},
};
</script>
<style scoped lang='less'>
.aricle {
  width: 1200px;
  margin: 0 auto 70px;

  h2 {
    font-size: 24px;
    color: #111;
    text-align: center;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    text-align: center;
    color: #111;
    line-height: 30px;
    margin: 15px 0;
  }

  p {
    text-indent: 2rem;
    line-height: 20px;
  }
  div {
    text-indent: 2rem;
    margin-top: 10px;
    .productName {
      line-height: 20px;
    }
  }
}
</style>