import {
    createApp
} from 'vue'
import App from './App.vue'
import router from '@/router'


const app = createApp(App)
// Vue.config.productionTip = false

import element from "element-plus";
import * as ElIconModules from "@element-plus/icons";
// 统一注册Icon图标
for (const iconName in ElIconModules) {
    if (Reflect.has(ElIconModules, iconName)) {
        const item = ElIconModules[iconName];
        app.component(iconName, item);
    }
}
import "element-plus/theme-chalk/index.css";
app.use(router).use(element).use(ElIconModules).mount('#app')