<template>
  <div>
    <Header></Header>
    <div class="supplier-banner">
      <img src="../../assets/recruitBg.jpg" />
    </div>
    <div class="supplier-conter">
      <div class="supplier-left">
        <h3>{{ productList[productListIndex].merchantUser }}</h3>
        <div class="hotline">商家用户: {{ productList[productListIndex].merchantUser }}</div>
        <div class="hotline">商店等级:{{ productList[productListIndex].merchantGrade }} </div>
        <!-- <div class="hotline">商品数量:111</div> -->
        <div class="hotline">创店时间: {{ productList[productListIndex].merchantTime }}</div>
      </div>
      <div class="supplier-right">
        
        <div class="body_container">
          <!-- 产品 -->
          <div
            class="floor-layout"
           v-for="(item, index) in productList[productListIndex].produtItem1"
                :key="index"
          >
          <h3>{{item.productGroup}}</h3>
            <!-- 产品集合 -->
            <div class="floor-tabs-panel">
              <!-- 单品 -->
              <div
                class="goods"
                v-for="(itemList, index) in item.produtItem"
                :key="index"
              >
                <div class="wrap">
                  <a
                    :href="'/goods?id=' + itemList.productId"
                    target="_blank"
                  >
                    <img
                      :src="itemList.productPic"
                      alt=""
                      class="pic_img"
                    />
                  </a>
                  <p class="title">
                    <a
                      target="_blank"
                      href=""
                      title=""
                    >{{ itemList.productName }}</a>
                  </p>
                  <p class="">
                    <span class="price">售价:<span class="price1">¥{{ itemList.price }}</span></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import productList from "../../api/supplier.js";
export default {
  name: "EdiSearch",
  data() {
    return {
      productList: [],
      productListIndex:''
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.productList = productList
    this.productListIndex = productList.findIndex(item => item.mallId == this.$route.query.mallId);
    console.log(this.productListIndex)
    // this.productListIndex =  this.$route.query.mallId;
    console.log(this.$route.query.mallId)
  }
};
</script>
<style scoped lang='less'>
.supplier-banner {
  width: 100%;
  height: 500px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}

.supplier-conter {
  width: 1200px;
  margin: 40px auto 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .supplier-left {
    width: 20%;
    height: 200px;
    border: 1px solid #eaeaea;

    h3 {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background: #e4368f;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #fff;
    }

    .hotline {
      font-size: 16px;
      color: #666666;
      line-height: 40px;
      padding: 0 15px;
    }
  }

  .supplier-right {
    width: 78%;
    margin-left: 2%;

    h3 {
      height: 30px;
      line-height: 30px;
      background: #ff85b4;
      font-size: 16px;
      color: #fff;
      padding-left: 10px;
    }

    .body_container {
      width: 100%;

      .floor-layout {
        height: auto;

        .floor-tabs-panel {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .goods {
            // float: left;
            width: 25%;
            color: #2f2f2f;
            height: 300px;
            overflow: hidden;
            border-right: 1px solid #eee;
            border-bottom: 1px solid #eee;
            margin-right: -1px;

            .wrap {
              margin: 20px;
              margin-top: 50px;
              position: relative;
            }

            img {
              width: 140px;
              height: 140px;
              margin: 0 auto 10px;
              display: block;
            }

            h3 {
              font-weight: 700;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              _width: 100%;
              height: 20px;
              line-height: 20px;
            }

            .title {
              margin-bottom: 5px;
              height: 35px;
              overflow: hidden;
            }

            a {
              font: 12px/150% microsoft yahei;
              color: #777;
            }

            .price {
              font-size: 16px;
              font-weight: normal;
              font-family: Arial, Helvetica, sans-serif;
              color: #333;

              .price1 {
                font-size: 20px;
                color: red;
              }
            }
          }
        }
      }
    }
  }
}
</style>