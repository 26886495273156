<template>
    <!-- nav -->
    <div class="aricle">
        <h2>隐私政策</h2>
        <p>我平台注重保护用户个人信息及个人隐私。本隐私政策解释了用户（“您”）个人信息收集（以下或称“信息”）和使用的有关情况，本隐私政策适用于我平台向您提供的所有相关服务（包括但不限于电子商务、网络资讯、网络社交等）。如果您不同意本隐私政策的任何内容，您应立即停止使用我平台服务。当您使用我平台提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。
        </p>
        <div v-for="(itemGroup, index) in productList" :key="index">
            <h5>{{itemGroup.productGroup}}</h5>
            <div class="productName" v-for="(item, index) in itemGroup.produtItem" :key="index">{{item.productName}}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'EdiArticle',
        data() {
            return {
                productList: [{
                    productGroup: "一、您个人信息的收集",

                    produtItem: [{
                            productName: "1、您向我们提供的信息"
                        },
                        {
                            productName: "当您注册我平台账户及您在使用相关我平台服务时填写、提交及/或其他任何方式提供的信息，包括您的姓名、性别、出生年月日、身份证号码、护照姓、护照名、护照号码、电话号码、电子邮箱、收货地址、我平台钱包或网银在线账号、银行卡信息及相关附加信息（如您地址中的所在的省份和城市、邮政编码等）。您可以选择不提供某一或某些信息，但是这样可能使您无法使用我平台的许多特色服务。请您理解，我们使用您提供的信息是为了回应您的要求，为您在我平台购物或享受服务提供便利，完善我平台网站以及与您进行信息沟通。另外，我们可能会将您所提供的信息与您的我平台账户关联，用以识别您的身份。"
                        },
                        {
                            productName: "2、我们在您使用服务过程中获得的信息",

                        },
                        {
                            productName: "为了提高服务质量和用户体验，我们会留存您使用服务以及使用方式的相关信息，这类信息包括："
                        },
                        {
                            productName: "（1）您的浏览器和计算机上的信息。在您访问我平台网站或使用我平台服务时，我平台系统自动接收并记录的您的浏览器和计算机上的信息（包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据）。"
                        },
                        {
                            productName: "（2）您的位置信息。当您下载或使用我平台、其关联方及合作伙伴开发的应用程序（例如我平台APP），或访问移动网页使用我平台服务时，我平台可能会读取您的位置（大多数移动设备将允许您关闭定位服务，具体建议您联系您的移动设备的服务商或生产商）。"
                        },
                        {
                            productName: "（3）您的设备信息。我平台可能会读取您访问我平台或使用我平台服务时所使用的终端设备的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。"
                        },
                        {
                            productName: "（4）您的行为或交易信息。我平台可能会记录您访问我平台或使用我平台服务时所进行的操作以及您在我平台网站上进行交易的相关信息。"
                        },
                        {
                            productName: "除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与我们的客服团队联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我平台的关联方、我平台合作伙伴之间互动时我们获得的相关信息。同时，为提高您使用我平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的链接（“URL”）。"
                        },
                        {
                            productName: "3、来自第三方的信息"
                        },
                        {
                            productName: "指在您注册我平台账户和使用服务过程中，您授权我平台可向我平台的关联方、合作伙伴获取其所收集的相关信息。这些信息包括但不限于您的身份信息、行为信息、交易信息、设备信息等，我平台会将此类信息汇总，用于帮助我平台向您提供更好以及更加个性化的服务或更好的预防互联网欺诈。"
                        },
                        {
                            productName: "您了解并同意，以下信息不适用本隐私政策："
                        },
                        {
                            productName: "（1）您在使用我平台提供的搜索服务时输入的关键字信息；"
                        },
                        {
                            productName: "（2）信用评价、违反法律法规规定或违反我平台平台规则行为及我平台已对您采取的措施；"
                        },
                        {
                            productName: "（3）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。"
                        },
                        {
                            productName: "（4）其他您与我平台或我平台的关联方所签署的协议（包括在线签署的电子协议，例如《服务协议》）以及我平台平台规则中明确约定或提示您不适用本隐私政策的与您有关的信息。"
                        }
                    ],

                }, {
                    productGroup: "二、我们对您个人信息的管理和使用",

                    produtItem: [{
                            productName: "为向您提供服务、提升我们的服务质量以及优化您的服务体验，我们会在符合法律规定下使用您的个人信息，并主要用于下列用途："
                        },
                        {
                            productName: "1、向您提供您使用的各项服务，并维护、改进这些服务。"
                        },
                        {
                            productName: "2、向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或在征得您同意的情况下与我平台的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如您不希望接收上述信息，可通过相应的退订功能进行退订。",

                        },
                        {
                            productName: "3、我们可能使用您的个人信息以验证身份、预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、其他我平台用户，或我们或其关联方的合法权益。"
                        },
                        {
                            productName: "4、我们可能会将来自某项服务的个人信息与来自其他服务所获得的信息结合起来，用于为了给您提供更加个性化的服务使用，例如为让您通过购物拥有更广泛的社交圈而使用、共享或披露您的信息。"
                        },
                        {
                            productName: "5、我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。"
                        },
                        {
                            productName: "6、让您参与有关我们产品及服务的调查。"
                        },
                        {
                            productName: "7、经您同意或授权的其他用途。"
                        },
                    ],

                }, {
                    productGroup: "三、您个人信息的分享",

                    produtItem: [{
                            productName: "您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的个人信息披露给第三方："
                        },
                        {
                            productName: "1、征得您的同意或授权。"
                        },
                        {
                            productName: "2、根据法律法规的规定或行政或司法机构的要求。",

                        },
                        {
                            productName: "3、出于实现“我们对您个人信息的管理和使用”部分所述目的，或为履行我们在《服务协议》或本隐私政策中的义务和行使我们的权利，向我平台的关联方、合作伙伴或代表我平台履行某项职能的第三方（例如代表我们发出推送通知的通讯服务商、处理银行卡的支付机构等）分享您的个人信息。"
                        },
                        {
                            productName: "4、如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能产生的权利纠纷。"
                        },
                        {
                            productName: "5、只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。"
                        },
                        {
                            productName: "6、您出现违反中国有关法律、法规规定或者违反您与我平台签署的相关协议（包括在线签署的电子协议）或违反相关我平台平台规则时需要向第三方披露的情形。"
                        },
                        {
                            productName: "7、为维护我平台及其关联方或其他我平台用户的合法权益。"
                        }, {
                            productName: "随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。"
                        },
                    ],

                }, {
                    productGroup: "四、您个人信息的安全",

                    produtItem: [{
                            productName: "我平台严格保护您的个人信息安全。我们使用各种制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、篡改、披露或破坏。如果您对我们的个人信息保护有任何疑问，请联系我们的客服。"
                        },
                        {
                            productName: "在通过我平台网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络我们的客服，以便我们采取相应措施。"
                        }
                    ],

                }],
            }
        },
        components: {
        }
    }
</script>
<style scoped lang='less'>
    .aricle {
        width: 1200px;
        margin: 0 auto 70px;

        h2 {
            font-size: 24px;
            color: #111;
            text-align: center;
            line-height: 30px;
            margin: 15px 0;

        }

        p {
            text-indent: 2rem;
            line-height: 20px;
        }

        div {
            text-indent: 2rem;
            margin-top: 10px;

            .productName {
                line-height: 20px;
            }
        }
    }
</style>