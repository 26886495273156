<template>
  <div class="log">
    <div class="log_tit">
      <div class="log_log">
        <a href="/" ><img class="header-logo" src="../../assets/logo-banner.png"></a>
      </div>
      <h2 class="log_text">登录</h2>
    </div>
    <div class="log_banner">
      <div class="log_relative">
        <div class="log_form">
          <div class="log_signup">
            <h3>用户登录</h3>
            <h2 class="log_text">
              <router-link class="header-signup" to="/signup">手机注册</router-link>
            </h2>
          </div>
          <el-form :model="formLabelAlign" status-icon class="demo-ruleForm">
            <el-form-item>
              <el-input
                v-model="formLabelAlign.inputName"
                prefix-icon="iphone"
                placeholder="用户名/手机号"
                width="300px"
                height="40px"
              />
            </el-form-item>
            <el-form-item>
              <el-input v-model="formLabelAlign.inputPasswode1" type="password" prefix-icon="lock" placeholder="密码"
                show-password />
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="formLabelAlign.checked2"></el-checkbox>
              <p>请保存我这次的登录信息</p>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "EdiLogin",
    data() {
      return {
        formLabelAlign: {
          inputName: "",
          inputPasswode1: "",
          checked2: false
        },
      };
    },
    methods: {
      submitForm() {
        if (this.formLabelAlign.inputName == window.sessionStorage.getItem("inputName") && this.formLabelAlign.inputPasswode1 == window.sessionStorage.getItem("inputPasswode")) {
          this.$message({
            message:'登录成功',
            type:'success'
          })
          window.sessionStorage.setItem("loginToken", true);
          this.$router.push('/')
        } else (
           this.$message({
            message:'用户名或密码不正确',
            type:'warning'
          })
        )
        
      }
    }
  };
</script>
<style scoped lang="less">
  .log {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    padding-bottom: 70px;
  }

  .log_tit {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .log_tit .log_log {
    width: 290px;
    height: 70px;
    text-align: center;
    line-height: 70px;
  }

  .log_tit .log_text {
    border-left: 1px #eee solid;
    width: 290px;
    height: 44px;
    line-height: 44px;
    font-size: 28px;
    margin: 26px 0px 0px 15px;
    padding: 0px 15px;
  }

  .log_banner {
    height: 470px;
    background: url(../../assets/Announce.jpg) no-repeat center;
    background-size: cover;
    margin: 20px auto 0;
  }

  .log_relative {
    width: 1000px;
    height: 470px;
    margin: 0 auto;
    position: relative;
  }

  .log_form {
    overflow: hidden;
    border: 1px #ddd solid;
    width: 300px;
    min-height: 355px;
    height: auto;
    position: absolute;
    z-index: 1;
    width: 306px;
    background: #fff;
    padding: 20px;
    overflow: visible;
    top: 40px;
    right: 0;

    .log_signup {
      display: flex;
      justify-content: space-between;
    }
  }

  .el-form-item {
    margin: 30px auto;
  }

  .log_form p {
    margin-left: 5px;
    color: #333;
  }

  .log_form p a {
    color: blue;
  }
  .header-logo{
    width: 290px;
  }
</style>