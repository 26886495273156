<template>
  <Header></Header>
  <!-- nav -->
  <div class="nav-items-container">
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      text-color="#333"
      active-text-color="#ff4200"
      @select="handleSelect"
    >
      <el-menu-item
        v-for="(item, index) in showItems"
        :key="index"
        :index="item.path"
      >
        <span>{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
  <div class="body_container">
    <!-- 产品 -->
    <div class="floor-list">
      <div class="floor">
        <div
          class="floor-layout"
          v-for="(itemGroup, index) in productList"
          :key="index"
        >
          <div class="floor-con">
            <div class="floor-title">
              <h2>{{ itemGroup.productGroup }}</h2>
            </div>
            <!-- 产品集合 -->
            <div class="floor-content">
              <div class="floor-right">
                <div class="floor-tabs-panel">
                  <!-- 单品 -->
                  <div
                    class="goods"
                    v-for="(item, index) in itemGroup.produtItem"
                    :key="index"
                  >
                    <div class="wrap">
                      <a
                        @click="toGoods(item.productId)"
                      >
                        <img
                          :src="item.productPic"
                          alt=""
                          class="pic_img"
                        />
                      </a>
                      <p class="title">
                        {{ item.productName }}
                      </p>
                      <p class="price">
                        <span>¥{{ item.price }}</span>
                      </p>
                      <!-- <a
                        class="add-cart"
                        @click="addToCart(item)"
                        title="加入购物车"
                      ></a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <shopCart
    ref="totalShop"
    :cartList="cartList"
    :totalPrice="totalPrice"
  ></shopCart>
  <Footer></Footer>
</template>
<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import shopCart from "../../components/shopCart.vue";
import productList from "../../api/home.js";

export default {
  name: "EdiHome",
  components: {
    Header,
    Footer,
    shopCart,
  },
  data() {
    return {
      activeIndex: "首页",
      showItems: [
        {
          path: "首页",
          title: "首页",
        },
        {
          path: "日用品",
          title: "日用品",
        },
        {
          path: "美妆专区",
          title: "美妆专区",
        },
        {
          path: "服装品",
          title: "服装品",
        },
      ],
      productList: [],
      products: productList,
      cartList: [],
    };
  },
  created() {
    this.productList = productList;
    window.sessionStorage.getItem("inputName");
    console.log(window.sessionStorage.getItem("inputName"));
    console.log(window.sessionStorage.getItem("inputPasswode"));
  },
  methods: {
    //跳转详情
    toGoods(id) {
      this.$router.push('/goods?id=' + id);
    },
    handleSelect(key) {
      // console.log(key);
      const productArr = []
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].productGroup == key) {
          productArr.push(this.products[i])
        }
      }
      if (key == '首页') {
        this.productList = this.products
      } else {
        this.productList = productArr
      }
    },
    /**点击添加购物车 */
    // addToCart(item) {
      // this.cartList = JSON.parse(window.sessionStorage.getItem("shopCartList"));
      // this.cartList.push(item);
      // window.sessionStorage.setItem(
        // "shopCartList",
        // JSON.stringify(this.cartList)
      // );
      // this.$refs.totalShop.totalPriceFun();
    // },
  },
};
</script>
<style scoped lang="less">
.nav-items-container {
  width: 1200px;
  margin: 0 auto;
  .el-menu--horizontal {
    width: 1200px;
    height: 40px;
    span {
      line-height: 40px;
      font-size: 16px;
    }
  }
}
.body_container {
  width: 100%;
  .floor-list {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .floor-layout {
      height: auto;
      .floor-con {
        height: auto;
        margin: 10px auto;
        position: relative;
        .floor-title {
          display: flex;
          height: 35px;
          line-height: 35px;
          overflow: hidden;
          h2 {
            font-size: 18px;
            color: #666;
            height: 35px;
            line-height: 35px;
          }
        }
        .floor-content {
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          overflow: hidden;
          .floor-right {
            width: 1200px;
            .floor-tabs-panel {
              border-top: 1px #e31939 solid;
              width: 1200px;
              display: flex;
              flex-wrap: wrap;
              .goods {
                // float: left;
                width: 300px;
                color: #2f2f2f;
                height: 300px;
                overflow: hidden;
                border-right: 1px solid #eee;
                border-bottom: 1px solid #eee;
                margin-right: -1px;
                .wrap {
                  margin: 20px;
                  margin-top: 50px;
                  position: relative;
                }
                img {
                  width: 140px;
                  height: 140px;
                  margin: 0 auto 10px;
                  display: block;
                }
                h3 {
                  font-weight: 700;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  _width: 100%;
                  height: 20px;
                  line-height: 20px;
                }
                .title {
                  margin-bottom: 5px;
                  height: 35px;
                  overflow: hidden;
                }
                a {
                  font: 12px/150% microsoft yahei;
                  color: #777;
                }
                .price {
                  font-size: 18px;
                  font-weight: normal;
                  font-family: Arial, Helvetica, sans-serif;
                  color: #e31939;
                }
                a.add-cart {
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 32px;
                  height: 32px;
                  cursor: pointer;
                  background: url(https://img0.baidu.com/it/u=437559451,3358437541&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500)
                    no-repeat;
                  background-size: cover;
                  // background-position: -165px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
