<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>

// import Header from './components/Header.vue'
// import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
      // Header,
      // Footer
  }
}
</script>

<style>

</style>
