<template>
  <div class="footer_container">
    <div class="footer_list">
      <ul>
        <li><a href="#">服务支持</a></li>
        <li><a href="#">售前咨询 18032703250</a></li>
        <li><a href="#">售后咨询 18032703250</a></li>
        <li><a href="#">客服 QQ 3405271363</a></li>
        <li><a href="#">工作时间 8：30-18：00</a></li>
      </ul>
    </div>
    <div class="pageInfo">
      <p class="nav_bottom">
        ICP备案证书号:<a
          href="http://beian.miit.gov.cn/"
          target="_blank"
        >冀ICP备15026482号-2</a>
      </p>
      <div><span>© 2005-2022 众博信息技术股份有限公司 版权所有，并保留所有权利。</span> <span>河北省沧州市运河区解放路颐和大厦19楼</span><span> Tel: 18032703250</span></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EdiFooter",
};
</script>
<style scoped lang="less">
.footer_container {
  width: 100%;
  height: 300px;
  background: #3a3a3a;
  padding: 40px 0 20px;
  color: #cccccc;

  .footer_list {
    display: flex;
    width: 1210px;
    margin: 0 auto;

    ul {
      margin-right: 100px;
      margin-top: 20px;

      li {
        margin-bottom: 5px;

        a {
          color: #ccc;
          font-size: 14px;
        }

        img {
          width: 100px;
          height: 100px;
        }
      }

      li:nth-child(1) {
        margin-bottom: 10px;

        a {
          color: #ccc;
          font-size: 18px;
        }
      }
    }
  }

  .pageInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    color: #ccc;

    ul {
      display: flex;

      li {
        margin-right: 10px;

        a {
          color: #ccc;
        }
      }
    }

    span {
      margin-top: 10px;
      color: #ccc;
    }
    span:hover {
      color: red;
    }
  }
}
.nav_bottom a {
  padding: 0px 8px;
  color: #ccc;
}
</style>